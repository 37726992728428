@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/var-map';

.header-nav {
  gap: $space-s;
  text-transform: uppercase;
  min-width: calc($space-xl * 8);
  width: 100% !important;

  @include for-tablet-portrait-down {
    min-width: unset;
  }
}

.equal-width {
  width: 100%;
  .nav-item {
    flex: 1;
    text-align: center;
    overflow: hidden;
  }
}

.nav-item {
  padding: $space-s + $space-xs;
  margin-right: $space-s;
  color: Nav('text-color');
  &:not(.is-active) {
    p,
    span {
      color: Nav('text-color');
    }
    svg {
      fill: Nav('text-color');
    }
  }

  transition: background-color 0.5s ease;
  border-radius: $border-radius;
  cursor: pointer;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: Nav('hover-background');
      p,
      span {
        color: Nav('hover-text-color') !important;
      }
      svg {
        fill: Nav('hover-text-color') !important;
      }
    }
  }
  &.is-active {
    background-color: Nav('active-background');
    p,
    span {
      color: Nav('active-text-color') !important;
    }
    svg {
      fill: Nav('active-text-color') !important;
    }
  }

  @include for-tablet-portrait-down {
    margin: 0 $space-s;
  }
}

.nav-item--inverted {
  @extend .nav-item;
  p,
  span {
    color: Nav('text-color-inverted') !important;
  }
  svg {
    fill: Nav('text-color-inverted') !important;
  }

  border-radius: calc($border-radius * 0.75);

  &:hover {
    background-color: Nav('hover-background-inverted');
    p,
    span {
      color: Nav('text-color-inverted') !important;
    }
    svg {
      fill: Nav('text-color-inverted') !important;
    }
  }

  &.is-active {
    background-color: Nav('active-background-inverted');
    p,
    span {
      color: Nav('active-text-color-inverted') !important;
    }
    svg {
      fill: Nav('active-text-color-inverted') !important;
    }
  }
}
