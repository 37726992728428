@import '../../css/variables/all';

.tag {
  display: inline-block;
  padding: $space-xs $space-s;
  background-color: $neutral;
  border-radius: $border-radius-circle;
  &.is-color-green {
    background-color: $success;
  }
  &.is-color-yellow {
    background-color: $warning;
  }
}
