@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers.scss';
@import '../../css/mixins/breakpoints';

.sidebar {
  cursor: pointer;
  height: 100vh;
  width: $space-side-bar-closed;
  top: 0px;
  position: fixed;
  left: 0px;
  background-color: SideBar('background');
  z-index: z('navbar') + 1;
  &.is-variant-click {
    padding: $space-m $space-s + $space-xs;
    &.is-open {
      cursor: default;
      padding: $space-m;
    }
  }
}

.side-bar-icon {
  margin: $space-m $space-s + $space-xs;
}

.toggle-icon {
  margin-bottom: $space-m;
  cursor: pointer;
}


.side-header {
  margin-bottom: $space-m;
  &.is-open {
    border-bottom: 1px solid SideBar('header-border');
  }
}

.wrapper {
  height: 100%;
}
