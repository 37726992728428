@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/var-map';

.container {
  &.is-combined-spacing-dense {
    padding: $space-inset-stretch-m;
  }
  &.is-combined-spacing-default {
    padding: $space-inset-stretch-xl;
    @include for-tablet-portrait-down {
      padding: $space-inset-stretch-l;
    }
  }
  &.is-combined-spacing-relaxed {
    padding: $space-inset-squish-xl;
  }
  &.is-width-fluid {
    flex-grow: 1;
    width: auto;
    max-width: 100%;
  }
  &.is-width-narrow {
    flex-shrink: 0;
    max-width: $space-container-narrow;
  }
  &.is-width-default {
    max-width: $space-container-default;
  }
  &.is-width-wide {
    max-width: $space-container-wide;
  }
  &.is-center {
    margin-left: auto;
    margin-right: auto;
  }
  &.is-right {
    margin-left: auto;
    margin-right: 0;
  }
  &.is-color-light {
    background: Container('light');
  }
  @each $unit, $size in $spacing-units {
    &.is-horizontal-spacing-#{$unit} {
      padding-right: $size;
      padding-left: $size;
    }
    &.is-vertical-spacing-#{$unit} {
      padding-top: $size;
      padding-bottom: $size;
    }
  }
}

.container-row {
  @include for-tablet-landscape-up {
    display: flex;
    &.is-hairline {
      > .container {
        &:not(:last-child) {
          border-right: $border-width solid $border-hairline;
        }
      }
    }
  }
}
