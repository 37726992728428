@import '~tnx-ui/src/css/variables/all';

.count-badge {
  margin-top: -16px;
}

.intermediate-stops {
  width: $space-l;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
}

.tooltip {
  margin-bottom: -22px;
}
