$width-phone: 450px;
$width-tablet-portrait: 700px;
$width-tablet-landscape: 900px;
$width-desktop: 1200px;
$width-desktop-wide: 1800px;
$width-desktop-giant: 2400px;

@mixin for-phone-only {
  @media (max-width: $width-phone) {
    @content;
  }
}

@mixin for-tablet-portrait-down {
  @media (max-width: ($width-tablet-portrait - 1)) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: $width-tablet-portrait) {
    @content;
  }
}

@mixin for-tablet-landscape-down {
  @media (max-width: ($width-tablet-landscape - 1)) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: $width-tablet-landscape) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $width-desktop) {
    @content;
  }
}

@mixin for-wide-desktop-up {
  @media (min-width: $width-desktop-wide) {
    @content;
  }
}

@mixin for-giant-desktop-up {
  @media (min-width: $width-desktop-giant) {
    @content;
  }
}
