@import '../../css/variables/all';

.label {
  display: flex;
  align-items: center;
  margin: $space-stack-s;
}

.spinner {
  margin-left: $space-s;
}
