@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/functions/var-map';
@import '../../css/mixins';

.user-defined-empty {
  padding: 0 $space-m;
}
.defined-filters-list {
  max-height: 85vh;
  overflow-y: auto;
}
.defined-filters-list-item {
  padding: $space-m - $space-xs $space-m $space-m - $space-xs $space-m;
  margin-right: $space-s;
  cursor: pointer;
  border-top: 2px solid;
  border-right: 2px solid;
  border-bottom: 2px solid;
  border-color: SideBar('background');
  border-top-right-radius: $border-radius * 8;
  border-bottom-right-radius: $border-radius * 8;
  transition: all 0.3;
  @include for-tablet-portrait-down {
    border: 2px solid $border-hairline;
    border-radius: $border-radius * 8;
  }
  &:hover {
    background-color: transparentize($color: $neutral--dark-1, $amount: 0.8);
    border-color: transparentize($color: $neutral--dark-1, $amount: 0.8);
  }
  &.is-active {
    border-color: Icon('primary');
  }
}

.filter-name {
  max-width: 65%;
}
.long-name-tooltip {
  margin-left: $space-xl;
}
