@import '../../css/variables/all';
@import '../../css/functions/var-map';

.wrapper {
  display: flex;
}

.count-badge {
  display: flex;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  background: CountBadge('background');
  border-radius: $border-radius;
  align-items: center;
  justify-content: center;
  padding: $space-inset-squish-s;
  &.is-color-primary {
    background: CountBadge('background-primary');
    p {
      color: CountBadge('text-primary');
    }
  }
  &.is-color-dark {
    background: CountBadge('background-dark');
    p {
      color: CountBadge('text-contrast');
    }
  }
  &.is-color-success {
    background: CountBadge('background-success');
    p {
      color: CountBadge('text-contrast');
    }
  }
  &.is-color-danger {
    background: CountBadge('background-danger');
    p {
      color: CountBadge('text-contrast');
    }
  }
  &.is-color-warning {
    background: CountBadge('background-warning');
  }
}
