@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/functions/var-map';

.mobile-filters-wrapper {
  padding: $space-m;
  height: 75vh;
  overflow-y: auto;
}

.saved-filter-heading {
  margin: $space-s $space-m;
}

.sort-wrapper {
  padding-right: $space-l;
  padding-left: $space-l;
}
