@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/functions/z-layers';

.activities-map {
  display: none;
  position: sticky;
  top: $space-m;
  height: 35vh;
  width: 100%;
  margin-bottom: $space-xl + $space-m;
  z-index: z('overlay');
  box-shadow: $neutral--light-4 0 56px 60px 22px;
  @include for-tablet-landscape-up {
    margin-bottom: $space-m;
    box-shadow: $neutral--light-4 0px 40px 28px 0px;
  }
  &.is-visible {
    display: block;
  }
}

.activities-map-close-button-wrapper {
  position: relative;
  width: 100%;
  margin-top: $space-s;
  z-index: z('overlay') + 1;
  text-align: right;
}

.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.activity-services {
  width: 50%;
}
