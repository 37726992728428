@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers.scss';

.container {
  position: fixed;
  display: flex;
  list-style: none;
  z-index: z('toast');
  pointer-events: none;
  &.is-position-bottom-center {
    top: 0;
    right: 0;
    bottom: $space-m;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }
  &.is-position-bottom-left {
    top: 0;
    left: $space-m;
    bottom: $space-m;
    flex-direction: column;
    justify-content: flex-end;
  }
  &.is-position-bottom-right {
    top: 0;
    right: $space-m;
    bottom: $space-m;
    flex-direction: column;
    justify-content: flex-end;
  }
  &.is-position-top-left {
    top: $space-top-bar-height + $space-m;
    left: $space-m;
    bottom: 0;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  &.is-position-top-right {
    top: $space-top-bar-height + $space-m;
    right: $space-m;
    bottom: 0;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}

.toast {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  padding: $space-inset-stretch-xl;
  min-width: $space-xl * 4;
  max-width: $space-xl * 6;
  margin-bottom: $space-m;
  background: $neutral--light-4;
  border-radius: $border-radius;
  box-shadow: 0 2px 8px transparentize(black, 0.7);
  user-select: none;
  cursor: pointer;
  pointer-events: all;
  &:hover {
    background: $neutral--light-3;
  }
  &.has-button {
    cursor: initial;
  }
  &.is-type-default {
    border-left: 6px solid Toast(default);
  }
  &.is-type-danger {
    border-left: 6px solid Toast(danger);
  }
  &.is-type-success {
    border-left: 6px solid Toast(success);
  }
  &.is-type-warning {
    border-left: 6px solid Toast(warning);
  }
}
