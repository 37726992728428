@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/mixins/input';

.map-button {
  margin-top: -($space-xs * 0.5) * 0.5;
}

.places-text-input {
  width: 100%;
}

.input-end {
  @include input-append;
  padding: $space-xs * 0.5;
}

.has-draw-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 1px;
  // prohibit to overlay input border
  position: relative;
  transition: none;
}
