@import '~tnx-ui/src/css/variables/all';

.error-wrapper {
  position: relative;
}

.error {
  position: absolute;
  top: -$space-s * 1.5;
  right: $space-xs;
  white-space: nowrap;
  background: $neutral--light-4;
  padding: $space-inset-xs;
}
