@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

@mixin button-type($type: 'primary') {
  &.is-#{$type} {
    background-color: Button($type + '-regular-background');
    border-color: Button($type + '-regular-border');
    color: Button($type + '-regular-text');
    .icon {
      svg {
        fill: Button($type + '-regular-text');
      }
    }
    .spinner {
      border-top-color: Button($type + '-regular-text');
      border-bottom-color: Button($type + '-regular-text');
    }
    &:hover:enabled,
    &:focus:enabled {
      background-color: Button($type + '-hover-background');
      border-color: Button($type + '-hover-border');
      color: Button($type + '-hover-text');
      transition: border-color 0.15s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
      .icon {
        svg {
          fill: Button($type + '-hover-text');
        }
      }
    }
    &:focus:enabled {
      background-color: Button($type + '-hover-background');
      border-color: Button($type + '-hover-background');
      outline: 0;
    }
    &:active:enabled {
      background-color: Button($type + '-regular-background');
    }
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transition: opacity 0.15s ease-out;
}

.button {
  display: inline-flex;
  justify-content: center;
  text-transform: capitalize;
  align-items: center;
  align-self: flex-start;
  vertical-align: bottom; // Crucial for aligning inline elements vertically
  white-space: nowrap;
  padding: $space-s * 1.5 $space-m;
  @include for-tablet-portrait-down {
    padding: $space-m;
    width: 100%;
  }
  border-radius: $border-radius;
  border-width: $border-width;
  border-style: solid;
  opacity: 1;
  user-select: none;
  cursor: pointer;
  transition: border-color 0.15s ease-out, background-color 0.15s ease-out, color 0.15s ease-out, opacity 0.15s ease-out;
  @include button-type($type: 'danger');
  @include button-type($type: 'danger-link');
  @include button-type($type: 'dark-grey');
  @include button-type($type: 'danger-border');
  @include button-type($type: 'link');
  @include button-type($type: 'link-border');
  @include button-type($type: 'link-contrast');
  @include button-type($type: 'link-secondary');
  @include button-type($type: 'neutral');
  @include button-type($type: 'primary');
  @include button-type($type: 'primary-border');
  @include button-type($type: 'primary-link');
  @include button-type($type: 'secondary');
  @include button-type($type: 'success');
  @include button-type($type: 'warning');
  @include button-type($type: 'warning-link');
  @include button-type($type: 'light');
  &:disabled {
    opacity: 0.5;
    transition: opacity 0.15s ease-out;
  }
  .label {
    @include button-normal;
    display: inline-block;
  }
  &.is-large {
    padding: $space-inset-l;
    .label {
      @include button-large;
    }
  }
  &.is-small {
    padding: $space-inset-s;
    .label {
      @include button-small;
    }
  }
  &.is-full {
    width: 100%;
  }
  &.is-wide {
    padding: $space-inset-squish-l;
    &.is-large {
      padding: $space-inset-squish-xl;
    }
    &.is-small {
      padding: $space-inset-squish-m;
    }
  }
  &.is-icon-left {
    flex-direction: row;
    &.is-small {
      .icon {
        margin: $space-inline-xxs;
      }
    }
    .icon,
    .spinner {
      margin: $space-inline-xs;
      margin-left: -$space-inline-xs;
    }
  }
  &.is-icon-right {
    flex-direction: row-reverse;
    .label {
      margin: $space-inline-xs;
    }
    &.is-small {
      .label {
        margin: $space-inline-xxs;
      }
    }
    .icon,
    .spinner {
      margin-right: -$space-inline-xs;
    }
  }
  &.has-only-icon {
    border-radius: 100px;
    width: $space-m * 2;
    height: $space-m * 2;
  }
}

a.button {
  text-decoration: none;
  text-transform: none;
}
