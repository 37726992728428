@import '../../css/variables/all';
@import '../../css/mixins/input';
@import '../../css/functions/z-layers';

.number-input-wrapper {
  isolation: isolate; // Prevents layering issues causesd by z-index below
}

.input {
  @include input-base;
  @include input-type($type: 'danger');
  @include input-type($type: 'default');
  @include input-type($type: 'success');
  &.has-unit {
    z-index: z('base') + 1; // prohibit unit to overlay input border
    &.is-unit-leading {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 1px;
    }
    &:not(.is-unit-leading) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 1px;
    }
  }
}

.unit {
  z-index: z('base'); // prohibit unit to overlay input border
  &.is-leading {
    @include input-prepend;
  }
  &.is-trailing {
    @include input-append;
  }
}
