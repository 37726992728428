@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

@mixin chip-color($color: 'default', $variant: 'outline') {
  &.is-variant-#{$variant} {
    background: transparent;
    user-select: text;
  }
  &.is-color-#{$color} {
    background: transparent;
    &.is-variant-#{$variant} {
      box-shadow: 0 0 0 2px Chip($color + '-' + $variant + '-border-regular');
      background: Chip($color + '-' + $variant + '-background-regular');
      svg {
        fill: Chip($color + '-' + $variant + '-icon-regular');
      }
      p {
        color: Chip($color + '-' + $variant + '-text-regular');
      }
      &.is-clickable:not(.is-delete-button-hovered) {
        user-select: auto;
        &:hover,
        &:focus {
          background: Chip($color + '-' + $variant + '-background-hover');
          box-shadow: 0 0 0 2px Chip($color + '-' + $variant + '-border-hover');
        }
        &:active {
          background: Chip($color + '-' + $variant + '-background-selected');
          box-shadow: 0 0 0 2px Chip($color + '-' + $variant + '-border-selected');
          svg {
            fill: Chip($color + '-' + $variant + '-icon-selected');
          }
          p {
            color: Chip($color + '-' + $variant + '-text-selected');
          }
        }
      }

      &.is-selected {
        background: Chip($color + '-' + $variant + '-background-selected');
        box-shadow: 0 0 0 2px Chip($color + '-' + $variant + '-border-selected'),
          0 0 0 6px Chip($color + '-' + $variant + '-border-selected-hover');
        svg {
          fill: Chip($color + '-' + $variant + '-icon-selected');
        }
        p {
          color: Chip($color + '-' + $variant + '-text-selected');
        }
      }

      .delete {
        outline: 0;
        cursor: pointer;
        border: 0;
        background: transparent;
        border-radius: $border-radius-circle;
        &:hover,
        &:focus {
          background: Chip($color + '-' + $variant + '-delete-button-hover');
        }
        &:active {
          background: Chip($color + '-' + $variant + '-delete-button-active');
        }
      }
    }
  }
}

.chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: $border-radius-circle;
  outline: 0;
  border: 0;
  @include chip-color($color: 'default', $variant: 'outline');
  @include chip-color($color: 'default', $variant: 'solid');
  @include chip-color($color: 'light', $variant: 'outline');
  @include chip-color($color: 'light', $variant: 'solid');
  @include chip-color($color: 'light-primary', $variant: 'outline');
  @include chip-color($color: 'light-primary', $variant: 'solid');
  @include chip-color($color: 'light-positive', $variant: 'outline');
  @include chip-color($color: 'light-positive', $variant: 'solid');
  @include chip-color($color: 'light-secondary', $variant: 'outline');
  @include chip-color($color: 'light-secondary', $variant: 'solid');
  @include chip-color($color: 'neutral', $variant: 'outline');
  @include chip-color($color: 'neutral', $variant: 'solid');
  @include chip-color($color: 'negative', $variant: 'outline');
  @include chip-color($color: 'negative', $variant: 'solid');
  @include chip-color($color: 'positive', $variant: 'outline');
  @include chip-color($color: 'positive', $variant: 'solid');
  @include chip-color($color: 'warning', $variant: 'outline');
  @include chip-color($color: 'warning', $variant: 'solid');
  &.is-clickable {
    cursor: pointer;
  }
  &.is-size-default {
    padding: $space-s $space-m;
  }
  &.is-size-large {
    padding: $space-s $space-m;
  }
  &.is-size-small {
    padding: $space-xs * 1.5 $space-s + $space-xs;
  }
  &.is-size-extra-small {
    padding: $space-xs * 0.25 $space-s;
  }
}

.text {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.label-text {
  white-space: nowrap;
}

.value-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
