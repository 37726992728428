@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers';

$base: z('base');

.marker {
  display: flex;
  width: $space-m * 2;
  height: $space-m * 2;
  margin-top: -($space-m);
  margin-left: -($space-m);
  border-radius: $border-radius-circle;
  align-items: center;
  opacity: 1;
  justify-content: center;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 1px 2px;
  &.is-clickable {
    cursor: pointer;
    transition: all 0.3s;
  }
  &.is-color-primary {
    background-color: GoogleMap('marker-primary-background');
    &.is-clickable.is-hovered {
      position: relative;
      z-index: $base + 1;
      transform: scale(1.1);
    }
    &.is-selected {
      position: relative;
      z-index: $base + 1;
      background-color: GoogleMap('marker-primary-selected');
    }
  }
  &.is-color-secondary {
    background-color: GoogleMap('marker-secondary-background');
    &.is-clickable.is-hovered {
      position: relative;
      z-index: $base + 1;
      transform: scale(1.1);
    }
    &.is-selected {
      position: relative;
      z-index: $base + 1;
      background-color: GoogleMap('marker-secondary-selected');
    }
  }
  &.is-medium {
    width: $space-l * 1.5;
    margin-left: -($space-l * 0.75);
  }
  &.is-large {
    width: $space-xl * 1.25;
    margin-left: -($space-xl * 0.625);
  }
  &.is-largest {
    width: $space-xl * 1.5;
    margin-left: -($space-xl * 0.75);
  }

  &.is-super-cluster {
    position: relative;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      opacity: 0.2;
      border-radius: 100%;
      z-index: $base - 1;
    }

    &::before {
      padding: $space-s;
    }

    &::after {
      padding: $space-m;
    }
    &.is-cluster-small {
      &::before,
      &::after {
        background: GoogleMap('cluster-small-color');
      }
      background: GoogleMap('cluster-small-color');
      width: $space-m * 2;
      height: $space-m * 2;
      margin-top: -($space-m);
      margin-left: -($space-m);
    }
    &.is-cluster-medium {
      background: GoogleMap('cluster-medium-color');
      &::before,
      &::after {
        background: GoogleMap('cluster-medium-color');
      }
      width: ($space-m + $space-xs) * 2;
      height: ($space-m + $space-xs) * 2;
      margin-top: -($space-m + $space-xs);
      margin-left: -($space-m + $space-xs);
    }
    &.is-cluster-large {
      background: GoogleMap('cluster-large-color');
      &::before,
      &::after {
        background: GoogleMap('cluster-large-color');
      }
      width: ($space-m + $space-s) * 2;
      height: ($space-m + $space-s) * 2;
      margin-top: -($space-m + $space-s);
      margin-left: -($space-m + $space-s);
    }
  }
}

.marker-label {
  padding: $space-s;
}

.marker-pin {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50% 50% 50% 0;
  top: 50%;
  left: 50%;
  background-color: GoogleMap('marker-primary-background');
  box-shadow: 0 0 2px 1px transparentize(black, 0.8);
  position: absolute;
  transform: rotate(-45deg);
  margin: -40px 0 0 -12px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-delay: 1s;
  z-index: $base + 2;
}

.marker-pin-label {
  transform: rotate(45deg);
  margin-top: -($space-s);
  margin-left: $space-s;
  background-color: GoogleMap('marker-primary-background');
  padding: $space-inset-squish-m;
  border-radius: $border-radius-circle;
  box-shadow: 0 -6px 6px -6px transparentize(black, 0.8);
}

.marker-list {
  border-radius: $border-radius;
  position: absolute;
  z-index: $base + 9;
  width: auto;
  min-width: 300px;
  background-color: Box('background');
  box-shadow: 0 0 2px 1px transparentize(black, 0.8);
}

.draw-controls-container {
  //ensure on top on mobile
  z-index: $base + 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.draw-controls {
  position: absolute;
  top: $space-s;
  right: $space-s;
}
.draw-map-clear {
  background: Box('background');
  border-radius: $border-radius;
}

.marker-label-button {
  background: none;
  border: none;
  border-color: transparent;
  outline: none;
}

.reset-zoom-control {
  background-color: $neutral--light-4;
  border-radius: $border-radius*0.5;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  padding: $space-xs;
  margin: 10px;
  padding: $space-xs;

  svg {
    fill: #333 !important;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(10px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    transform: translateY(-10px) rotate(-45deg);
  }
  80% {
    transform: translateY(-5px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
