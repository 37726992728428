@import '../../css/variables';
@import '../../css/mixins';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/functions/z-layers';

$base: z('base');

.tenders-view {
  display: flex;
  min-height: calc(100vh - #{$space-top-bar-height});
}

.tenders-main-wrapper {
  z-index: $base + 1;
  width: 100%;
  flex-grow: 1;
  flex-direction: row;
  border-right: 1px solid $neutral--light-3;
  padding-top: $space-l;
  @include for-desktop-up {
    width: 66%;
    max-width: 66%;
  }
  @include for-tablet-portrait-down {
    padding-top: 0px;
  }
}

.tenders-container {
  flex-grow: 1;
  padding: $space-m;
  &.is-map-view {
    @include for-tablet-portrait-down {
      padding: 0px;
    }
  }
}

[data-fullscreen='true'] {
  .tenders-main-wrapper {
    margin-top: 0px;
    top: 0px;
    position: absolute;
    z-index: z('navbar') + 1;
    @include for-desktop-up {
      z-index: z('navbar') + 1;
    }
  }
}

[data-map-drawing='true'] {
  .tenders-main-wrapper {
    z-index: z('modal') - 1;
    @include for-desktop-up {
      z-index: z('navbar') + 1;
    }
  }
}

.tenders-search-header-wrapper {
  position: relative;
  z-index: $base + 1;
}

.tender-list-map-container {
  position: absolute;
  width: 100%;
  height: 50vh;
}

.filter-animation-dummy {
  background-color: $core--light-1;
  border-radius: $border-radius;
}

.side-menu-button {
  position: absolute;
  top: $space-top-bar-height;
  left: $space-m;
  z-index: $base + 2;
}

.side-menu-icon-space {
  margin-left: -$space-s;
}

.new-tenders-notify {
  margin-bottom: $space-m;
}

.recently-opened-container {
  padding: $space-s $space-m;
  margin-top: $space-m;
  text-align: center;
}

.result-count {
  margin-left: $space-m;
  min-width: $space-xl + $space-l;
}

.results-spinner-sort {
  display: block;
  @include for-wide-desktop-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.results-spinner {
  margin-bottom: $space-l;
  display: flex;
  justify-content: flex-start;
  @include for-wide-desktop-up {
    margin-bottom: 0;
  }
}

.fav-checkbox {
  padding-top: $space-xs * 1.5;
}
