@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/functions/z-layers';

.message-body {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.blocking-message {
  position: relative;
  overflow-x: hidden;
  z-index: z('toast') + 1;
  > div {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.header-wrapper {
  padding-top: $space-l;
}
