@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/functions/var-map.scss';
@import '~tnx-ui/src/css/mixins/breakpoints.scss';
@import '~tnx-ui/src/css/functions/z-layers';

$margin-map-view: $space-l + $space-xs;
$space-header-and-footer: ($space-top-bar-height * 2) + $space-m;
$base: z('base');

.trip-details,
.fullscreen-control,
.map-bounds-filter {
  position: absolute;
  z-index: $base + 2;
  top: $space-s;
  background-color: $neutral--light-4;
  border-radius: $border-radius;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.map-bounds-filter {
  top: $space-s + $space-xs;
  padding: $space-inset-squish-m;
  border-radius: $border-radius-circle;
  width: fit-content;
  max-width: 70%;
  left: 50%;
  transform: translate(-50%);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 3px 8px;
  &:hover,
  &:active {
    box-shadow: 0 0 0 2px Button('primary-regular-background'), rgba(0, 0, 0, 0.4) 0px 3px 8px;
  }
  @include for-desktop-up {
    top: $space-xl;
  }
}

.show-all-button-wrapper {
  position: absolute;
  z-index: $base + 1;
  top: $space-xl + $space-l;
  padding: $space-inset-m;
  width: 100%;
  display: flex;
  justify-content: center;
}

.trip-details {
  padding: $space-inset-m;
  left: $space-l;
}

.fullscreen-control {
  right: $space-s * 1.5;
  padding: $space-xs;
  z-index: $base + 2;
}

.fullscreen-message {
  position: absolute;
  z-index: $base + 4;
  opacity: 0;
  padding: $space-m;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  width: 300px;
  right: calc(50vw - 150px);
  border-radius: $border-radius;
  top: $space-s;
  -webkit-animation: fadeInOut 6s;
  animation: fadeInOut 6s;
}

.map-content {
  width: 100%;
  height: 100%;
}

.maps-container {
  display: none;
  height: calc(100vh - #{$space-top-bar-height});
  @include for-desktop-up {
    display: block;
    position: sticky;
    overflow: hidden;
    top: $space-top-bar-height;
    flex-grow: 1;
    flex-shrink: 0;
    width: 34%;
    max-width: 34%;
  }
  &.map-view {
    display: block;
    position: absolute;
    width: 100%;
    height: calc(100vh - #{$space-header-and-footer});
    overflow: hidden;
    margin-top: $margin-map-view;
  }
  &.maps-focus {
    position: fixed;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0px;
    left: 0px;
    z-index: $base + 2;
    @include for-tablet-portrait-down {
      height: calc(100vh - $space-top-bar-height);
      top: $space-top-bar-height;
    }
  }
  &.list-view {
    display: block;
    height: 0px;
    overflow: hidden;
    @include for-desktop-up {
      height: calc(100vh - #{$space-top-bar-height});
    }
  }
}

[data-fullscreen='true'] {
  .maps-container {
    position: fixed;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0px;
    left: 0px;
    margin-top: 0px;
    z-index: z('navbar') + 1;
  }
}

.tooltip-content {
  min-height: $space-xl * 2;
  min-width: $space-xl * 4;
}

.tender-tooltip-content {
  cursor: pointer;
  padding: $space-m;
  background: white;
}

.tender-tooltip-header {
  padding: $space-s $space-m 0px;
}

.slide-container {
  background-color: $neutral--light-4;
  height: 340px;
  overflow: hidden;
}

.tender-box {
  &:hover {
    background-color: CargoDetails(overall-hover-background);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  16% {
    opacity: 1;
  }
  84% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

[data-fullscreen='true'] {
  overflow: hidden;
}

.map-bounds-checkbox {
  display: flex;
}
