@import "~tnx-ui/src/css/variables/all";
@import '../../css/mixins';

.actions-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $space-l * 2;
  border-bottom: $border-width solid $border-dark-hairline;

  @include for-tablet-portrait-down {
    flex-direction: column;
  }
}

.new-tenders-notify {
  margin: $space-m;
}

.no-creditor-number {
  margin-right: $space-l * 4;

  @include for-tablet-portrait-down {
    margin-right: $space-l;
  }

  @include for-tablet-portrait-down {
    margin-right: 0;
    margin-bottom: $space-l;
  }
}

.custom-reference-number-modal {
  width: 30%;

  @include for-tablet-portrait-down {
    width: 50%;
  }

  @include for-phone-only {
    width: 80%;
  }
}

.modal-content {
  padding: 0 $space-l;
}

.modal-form-input {
  padding: 0 $space-l;
}

.line-separator {
  width: 100%;
  height: 1px;
  background-color: $border-dark-hairline;
}

.actions-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 $space-l;
}

.actions-custom-ref-number-container {
  @include for-tablet-portrait-down {
  width: 100%;
  opacity: 1;
  transform: none;
  margin-top: $space-l;
  text-align: center;
  }
}
