@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers.scss';

.tooltip {
  position: absolute;
  padding: $space-inset-stretch-m;
  margin-bottom: $space-s;
  background: Box('background');
  border-radius: $border-radius;
  box-shadow: 0 2px 8px transparentize(black, 0.7);
  z-index: z('modal') + 1;
}

.reference {
  min-width: $space-m;
}
