@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/typography';

.getting-started-emoji {
  @include heading-xxl();
  margin-right: $space-s;
  font-size: 4rem;
}

.getting-started-ping {
  margin-top: -7px;
}

.draw-on-map-animation {
  width: 100%;
  border-radius: $border-radius;
}
