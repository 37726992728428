@import '../../css/variables/all';

.spacing {
  margin: 0;

  @each $size, $spacing in $spacing-units {
    &.is-inline-#{$size} {
      margin-right: $spacing;
    }
    &.is-stack-#{$size} {
      margin-bottom: $spacing;
    }
  }
}
