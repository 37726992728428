@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/z-layers.scss';

.backdrop {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: z('dropdown') - 1;
  background: transparentize($core--dark-1, 0.7);
  pointer-events: none;
}
.popover {
  z-index: z('dropdown');
  &.is-fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
  }
}

.popover-container {
  background-color: MainMenu('background');
  border-radius: $border-radius;
  box-shadow: 0 2px 8px transparentize($neutral--dark-2, 0.7);
  margin-top: $space-xs;
  transform-origin: top left;
  &.is-fullscreen {
    height: 100%;
    margin-top: 0;
    border-radius: 0;
  }
}
