@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/mixins/typography';
@import '~tnx-ui/src/css/functions/var-map';

.wrapper {
  overflow: hidden;
}
.tab-list {
  display: flex;
  padding: $space-m $space-m 0px;
  background-color: Tabs('tablistBackgroundDefault');
  &.is-alignment-center {
    justify-content: center;
  }
  &.is-alignment-left {
    justify-content: flex-start;
  }
  &.is-color-light {
    background-color: Tabs('tablistBackgroundLight');
  }
  &.is-size-default {
    > .tab {
      @include body-l;
      padding: $space-inset-stretch-l;
      &.is-active {
        > span {
          &::after {
            bottom: -12px;
          }
        }
      }
    }
  }
  &.is-size-small {
    > .tab {
      @include body-bold;
      padding: $space-m;
      &.is-active {
        > span {
          &::after {
            bottom: -8px;
          }
        }
      }
    }
  }
  &.is-width-fixed {
    justify-content: space-around;
    > .tab {
      flex-grow: 1;
    }
  }

  @include for-tablet-portrait-down {
    padding: $space-s $space-xs 0px;
    background-color: Tabs('panelBackground') !important;
    border-bottom: 1px solid $border-dark-hairline;
    &.has-long-tab-list {
      padding: $space-m $space-m $space-xs;
      border-bottom: none;
      &:not(.is-dragging) {
        transition: all 0.3s;
      }
    }
  }
}

.tab {
  outline: 0;
  padding-top: 1px;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  text-transform: UPPERCASE;
  letter-spacing: 1px;
  color: Tabs('label');
  @include for-tablet-portrait-up {
    &:focus {
      background-color: Tabs('highlight');
    }
    &:hover {
      background-color: Tabs('highlight');
    }
  }
  &.has-errors {
    color: $danger;
  }
  &.is-active {
    @include for-tablet-portrait-up {
      background-color: Tabs('panelBackground');
    }
    color: Tabs('labelActive');
    > span {
      display: block;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        border-bottom: 2px solid Tabs('underlineActive');
      }
    }
    &:focus {
      @include for-tablet-portrait-up {
        box-shadow: 0 -2px 3px Tabs('shadowActive');
      }
    }
    &.has-errors {
      color: $danger;
      > span {
        &::after {
          border-bottom: 2px solid $danger;
        }
      }
    }
  }

  @include for-tablet-portrait-down {
    flex: 1;
  }
}

.has-long-tab-list {
  @include for-tablet-portrait-down {
    .tab {
      background-color: Tabs('tabPillBackgroundDefault');
      border-radius: $border-radius * 2;
      padding: $space-s * 1.25 $space-m !important;
      margin-right: $space-s;
      flex: none;
      &.is-active {
        background-color: Tabs('tabPillBackgroundActive');
        color: Tabs('tabPillColorActive');

        > span {
          &::after {
            bottom: -4px !important;
          }
        }
      }
    }
  }
}

.tab-panel {
  display: flex;
  flex-direction: column;
  outline: 0;
  padding-top: ($space-l + $space-m);
  padding-bottom: $space-l;
  background-color: Tabs('panelBackground');
}
