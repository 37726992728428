@import '../variables/all';
@import './typography';
@import '../functions/var-map';

@mixin input-type($type: 'default') {
  &.is-#{$type} {
    background-color: TextInput($type + '-regular-background');
    border-color: TextInput($type + '-regular-border');
    color: TextInput($type + '-regular-text');
    &:focus {
      outline: none;
      background-color: TextInput($type + '-focus-background');
      border-color: TextInput($type + '-focus-border');
      color: TextInput($type + '-focus-text');
      box-shadow: 0 0 0 1px TextInput($type + '-focus-border');
    }
    &::placeholder {
      color: TextInput($type + '-regular-placeholder');
    }
  }
}

@mixin input-base {
  @include input;
  padding: $space-inset-stretch-s;
  border-radius: $border-radius;
  border-width: $border-width;
  border-style: solid;
  border-color: TextInput('default-regular-border');
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow 0.15s ease-out;
  width: 100%;
  height: $space-l + $space-xs;
  &.is-small {
    @include input-small;
    padding: $space-inset-s;
    height: $space-l + $space-xs;
  }
  &:read-only {
    background-color: TextInput('readonly-regular-background');
    border-top-color: TextInput('readonly-regular-background');
    border-right-color: TextInput('readonly-regular-background');
    border-bottom-color: TextInput('readonly-regular-border');
    border-left-color: TextInput('readonly-regular-background');
    border-radius: 0;
    color: TextInput('readonly-regular-text');
    &:focus {
      outline: none;
      background-color: TextInput('readonly-focus-background');
      border-top-color: TextInput('readonly-regular-background');
      border-right-color: TextInput('readonly-regular-background');
      border-bottom-color: TextInput('readonly-regular-border');
      border-left-color: TextInput('readonly-regular-background');
      box-shadow: 0 0 0 white;
    }
  }
  &:disabled {
    background-color: TextInput('disabled-regular-background');
    border-color: TextInput('disabled-regular-border');
    color: TextInput('disabled-regular-text');
    resize: none;
    &:hover {
      cursor: not-allowed;
    }
  }
}

@mixin input-append {
  @include input;
  display: flex;
  align-items: center;
  background-color: TextInput('default-regular-background');
  border-color: TextInput('default-regular-border');
  padding: $space-inset-stretch-s;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-width: $border-width;
  border-style: solid;
  border-left: 0;
  box-shadow: 0 0 0 0 transparent;
  height: $space-l + $space-xs;
  &.is-small {
    @include input-small;
    padding: $space-inset-s;
    height: $space-l + $space-xs;
  }
}

@mixin input-prepend {
  @include input;
  display: flex;
  align-items: center;
  background-color: TextInput('default-regular-background');
  border-color: TextInput('default-regular-border');
  padding: $space-inset-stretch-s;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  border-width: $border-width;
  border-style: solid;
  border-right: 0;
  box-shadow: 0 0 0 0 transparent;
  height: $space-l + $space-xs;
  &.is-small {
    @include input-small;
    padding: $space-inset-s;
    height: $space-l + $space-xs;
  }
}
