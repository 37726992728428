@import '../../css/variables/all';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers';

.autocomplete-dropdown-container {
  background: MainMenu('background');
  box-shadow: 0 2px 8px transparentize($neutral--dark, 0.4);
  border-radius: $border-radius;
  z-index: z('base') + 3;
  &[data-placement*='bottom-start'] {
    margin-top: $space-s;
  }
  &[data-placement*='top-start'] {
    margin-bottom: $space-s;
  }
}

.suggestion-item {
  padding: $space-inset-m;
  cursor: pointer;
  border-bottom: 1px solid $border-hairline;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
}

.suggestion-item-active {
  padding: $space-inset-m;
  cursor: pointer;
  border-bottom: 1px solid $border-hairline;
  background: Select('hover-background');
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  .main-text,
  .main-secondary-text {
    color: $neutral--light-4;
  }
}

.suggestion-main-text {
  @include body-bold;
  display: block;
}

.suggestion-secondary-text {
  @include fine-print;
  display: block;
}
.search-input-wrapper {
  width: 100%;
}
.search-input {
  text-overflow: ellipsis;
}
