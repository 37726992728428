@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints.scss';
@import '~tnx-ui/src/css/functions/var-map.scss';

.actions-container {
  display: flex;
  align-items: center;
  @include for-tablet-portrait-down {
    flex-direction: column;
    min-height: $space-m * 14;
    padding-top: $space-s !important;
    &.has-bid {
      min-height: $space-m * 16;
    }
    &.is-matched,
    &.is-rejected {
      min-height: $space-l * 4;
    }
    &.has-accept-bid {
      min-height: $space-m * 18;
    }
  }
}

.actions-price-container {
  margin-right: $space-l;
  @include for-tablet-portrait-down {
    margin-right: 0px;
  }
}
.actions-buttons-container {
  position: relative;
}

.actions-buttons-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  @include for-tablet-portrait-down {
    flex-direction: column;
  }
}

.actions-button {
  position: absolute;
  white-space: nowrap;
}
.full-width-phone-actions-button {
  @include for-tablet-portrait-down {
    width: 100vw;
    padding: $space-inset-stretch-l;
  }
}
.actions-area {
  min-height: $space-l * 2;
  border-bottom: $border-width solid $border-dark-hairline;
  border-top: $border-width solid $border-dark-hairline;
}
.active-bid-wrapper {
  margin-bottom: $space-s;
  @include for-tablet-portrait-up {
    margin-right: $space-m;
    margin-bottom: 0px;
  }
}
.actions-bid-amount {
  width: $space-xl * 3;
  @include for-tablet-portrait-down {
    margin-bottom: $space-m;
    margin-right: 0px !important;
    width: 100%;
    text-align: center;
    input {
      text-align: center;
    }
  }
}

.actions-button-pre-match {
  display: flex;
  @include for-tablet-portrait-down {
    flex-direction: column;
  }
}

.flex-col-phone-only {
  @include for-tablet-portrait-down {
    flex-direction: column;
  }
}

.actions-buttons-text {
  padding-right: $space-s;
  @include for-tablet-portrait-down {
    padding: $space-m;
  }
}

.actions-button-element {
  display: inline-block;
  &:not(:last-child) {
    margin: 0px $space-s 0px 0px;
  }
  @include for-tablet-portrait-down {
    width: 100%;
    text-align: center;
    &:not(:last-child) {
      margin: 0px 0px $space-s 0px;
    }
  }
}

.tender-type {
  width: fit-content;
  margin-top: $space-s;
  padding: $space-xs $space-xs;
  background-color: Button('warning-regular-background');
}
