@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/mixins/breakpoints';

.list {
  width: $space-side-bar-width;
  flex-shrink: 0;
  padding: $space-inset-s;
  .list-item {
    cursor: pointer;
    padding: $space-inset-stretch-s;
    padding-right: 0;
    outline: 0;
    &:hover,
    &:focus,
    &.is-selected {
      background: SideMenu('active-background');
      &.color-dark {
        background: SideMenu('active-backround-dark');
      }
    }
  }
  .list-item-text {
    width: calc(#{$space-side-bar-width} - #{$space-default} * 4.5);
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: normal; // hide scroll bars
    &.has-indicator {
      width: calc(#{$space-side-bar-width} - #{$space-default} * 6.5);
    }
    &.has-facet {
      width: calc(#{$space-side-bar-width} - #{$space-default} * 7.5);
    }
    &.has-both {
      width: calc(#{$space-side-bar-width} - #{$space-default} * 9);
    }
  }
  @include for-tablet-portrait-down {
    border-right: 0;
    width: 100%;
    .list-item {
      padding: $space-s * 1.5 $space-s;
      padding-right: 0;
    }
  }
}
