@import '../../css/variables/all';
@import '../../css/functions/z-layers';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  text-align: center;
  background: $neutral--light-4;
  z-index: z('overlay');
}

.container {
  width: $space-container-default;
  max-width: 100%;
  padding: $space-m;
}
