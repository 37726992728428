@import '../../css/functions/var-map.scss';
@import '../../css/variables/all';
@import '../../css/mixins/breakpoints.scss';

.search-bar-default {
  border-color: NinjaSearch('default-regular-border');
  background: NinjaSearch('default-background');
  border-radius: $border-radius-round;
  border-width: $border-width;
  border-style: solid;
  box-shadow: 0px 0px 0px 0px transparent;
  transition: box-shadow 0.15s ease-out;

  &.is-size-default {
    padding-left: $space-m;
    padding-right: $space-m;
  }

  &.is-size-small {
    padding-left: $space-s;
    padding-right: $space-s;
  }

  &.focus {
    outline: none;
    background-color: TextInput('default-focus-background');
    border-color: TextInput('default-focus-border');
    color: TextInput('default-focus-text');
    box-shadow: 0 0 0 1px TextInput('default-focus-border');
  }

  &.has-no-result {
    border-color: $danger;
    box-shadow: 0 0 0 1px $danger;
  }
}

.search-bar-cancel {
  cursor: pointer;
}

.search-bar-input {
  width: calc(100% - #{$space-m} * 3);
}

[data-search='open'] {
  overflow: hidden;
}

.search-bar-neutral {
  @extend .search-bar-default;
  border-color: NinjaSearch('default-regular-border-blue');

  &.focus {
    border-color: NinjaSearch('default-regular-border-blue');
    box-shadow: 0 0 0 1px NinjaSearch('default-regular-border-blue');
  }
}
