@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/mixins/breakpoints';

.popover-container {
  background-color: Box('background');
  border-radius: $border-radius;
  box-shadow: 0 2px 8px transparentize($neutral--dark-2, 0.7);
  width: $space-m * 20;
  @include for-tablet-portrait-up {
    width: $space-m * 25;
  }
}
.back-arrow {
  align-self: center;
  margin-right: $space-s;
}

.popover-header,
.popover-body,
.popover-footer {
  padding: $space-inset-m;
}

.popover-header {
  padding-bottom: $space-s + $space-xs;
}

.popover-footer {
  padding-top: $space-s + $space-xs;
}

.popover-body {
  border-top: 1px solid $border-hairline;
  border-bottom: 1px solid $border-hairline;
}

.step-indicator {
  border-radius: $space-l;
  width: $space-s;
  height: $space-s;
  margin-right: $space-s + $space-xs;
  background-color: $neutral--light-1;
  &.is-current {
    background-color: $core;
  }
}
