@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/var-map';

$step-size: $space-l + $space-m;

.wizard-grid {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-template-rows: 1fr 10fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  @include for-tablet-portrait-down {
    grid-template-columns: 1fr;
  }
}

.wizard-side-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @include for-tablet-portrait-down {
    display: none;
  }
}

.wizard-footer {
  width: 100%;
  padding-left: $space-l;
  @include for-tablet-portrait-down {
    padding: $space-s 0px;
    flex-direction: column;
    position: fixed;
    bottom: 0px;
    left: 0px;
    background: $neutral--light-4;
  }
}

.wizard-main-container {
  padding: $space-l;
  overflow: hidden;
  @include for-phone-only {
    padding: 0px $space-m $space-m $space-m;
  }
}

.wizard-header {
  height: 100%;
}

.header-left {
  position: relative;
  &:after {
    content: '';
    background: $border-hairline;
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 50%;
    height: 1px;
  }
  @include for-tablet-portrait-down {
    display: none;
  }
}

.button-exit {
  cursor: pointer;
}

.step-count {
  position: relative;
  &:after {
    content: '';
    background: $border-hairline;
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 1px;
  }
  @include for-tablet-portrait-down {
    display: none;
  }
}

.step-list {
  transition: all 0.3s;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: $step-size + $space-l;
  left: 0px;
}

.item {
  transition: background-color 0.3s ease;
  display: block;
  width: $step-size;
  height: $step-size;
  border-radius: 50%;
  margin: $space-m;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  background-color: FormWizard('step-background-color');
  &:hover {
    background-color: FormWizard('step-background-color-hover');
  }
  &.is-completed {
    background-color: FormWizard('step-background-color-completed');
  }
  &.is-selected {
    background-color: FormWizard('step-background-color-selected');
  }
}

.outline {
  position: absolute;
  top: -$step-size*0.25;
  left: -$step-size*0.25;
  right: -$step-size*0.25;
  bottom: -$step-size*0.25;
  border: $step-size*0.125 solid white;
  border-radius: 50%;
  border-color: FormWizard('step-background-color-selected');
}

.icon-wrapper {
  height: 100%;
  width: 100%;
}

.progress-wrapper {
  background-color: $neutral--light-3;
  padding: $space-s $space-m;
  border-radius: $border-radius;
}

.tick-wrap {
  position: absolute;
  bottom: -$space-s;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -$space-xs;
  width: $space-m + $space-s;
  height: $space-m + $space-s;
  border-radius: 50%;
  background-color: FormWizard('step-background-color-success');
  &.is-dirty {
    background-color: FormWizard('step-background-color-dirty');
  }
  &.is-required {
    background-color: FormWizard('step-background-color-required');
  }
}

.dot-progress {
  padding: $space-m 0px;
}

.dot-progress-wrap {
  position: relative;
  .hover-step-text {
    position: absolute;
    width: $space-xl * 6;
    left: $space-xs;
    top: -$space-s;
  }
}

.icon-step-count {
  cursor: pointer;
}

.arrow-button-container {
  background-color: $neutral--light-3;
  padding: 0px $space-s;
  border-radius: $border-radius;
  margin-left: $space-m;
  @include for-phone-only {
    margin-left: 0px;
  }
}

.form-position-wrapper {
  @include for-phone-only {
    flex-direction: column;
  }
}

.mobile-arrows {
  display: none;
}

.progress-arrows {
  display: block;
}

@include for-phone-only {
  .progress-arrows {
    display: none;
  }
  .mobile-arrows {
    display: block;
    margin-left: $space-m;
  }
}
