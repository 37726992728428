@import '~tnx-ui/src/css/variables/all.scss';

.wrapper {
  display: flex;
  align-items: center;
  margin-left: -2px;
  overflow: hidden;
}

.name-wrapper {
  min-width: 0;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: $space-xs 0;
}

.email-link p {
  &:hover {
    color: $core;
  }
}
