@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers.scss';

.modal-opened {
  overflow: hidden;
  height: 100vh;
}

.modal-base {
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  transform: translateY(1500px);
  max-width: 100%;
  width: $space-modal-width;
  z-index: z('modal');
  background: Box('background');
  transition: transform 300ms ease-out;
  border-radius: $border-radius;
  overflow-y: auto;
  @include for-tablet-landscape-up {
    max-width: 90%;
    border-radius: 0px;
    transform: translateX($space-modal-width * 1.5);
    transition: transform 200ms ease-out;
  }
}

.modal-after-open {
  transform: translateY($space-xs);
  @include for-tablet-landscape-up {
    transform: translateX(0);
  }
}

.modal-before-close {
  transform: translateY(1500px);
  @include for-tablet-landscape-up {
    transform: translateX(2000px);
  }
}

.overlay-base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: z('overlay');
  background-color: transparentize($neutral--dark, 0.4);
  opacity: 0;
  transition: opacity 150ms ease-out;
}

.overlay-after-open {
  opacity: 1;
}
.overlay-before-close {
  opacity: 0;
}

.close-button-wrapper {
  position: sticky;
  background-color: Box('background');
  z-index: z('modal') + 1;
  height: $space-modal-close-header-height;
  top: 0px;
  width: 100%;
  @include for-tablet-landscape-up {
    &:not(.has-close-button) {
      display: none;
    }
  }
}
.close-button {
  svg {
    fill: Icon('dark') !important;
  }
  position: absolute;
  top: $space-s;
  right: $space-s;
  background: $neutral--light-3 !important;
  z-index: z('modal') + 2;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  transition: background 0.2s ease 0s;
}

.modal-header {
  width: 100%;
  top: $space-modal-close-header-height;
  z-index: z('modal') + 1;
  position: sticky;
  padding: 0px $space-m $space-m;
  border-bottom: 1px solid $border-dark-hairline;
  background-color: Box('background');
  &.has-children {
    padding: $space-m 0px;
  }
  .header-title {
    margin-left: $space-s;
  }
}

.header-left-content {
  flex-shrink: 1;
}

.footer-wrapper {
  padding: $space-m;
  bottom: 0px;
  position: sticky;
  width: 100%;
  background: Box('background');
  .submit-button {
    width: 100%;
  }
}

.modal-center-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: z('overlay');
  background-color: Box('overlay-background');
}

.modal-center {
  position: absolute;
  overflow: hidden;
  background: Box('background');
  outline: none;
  border-radius: $border-radius-round;
  box-shadow: 0 2px 8px transparentize($neutral--dark-2, 0.7);
}
