@import '../../css/variables/all';
@import '../../css/functions/var-map';

@mixin check-box-colors($color: 'default', $colorContext: 'bright', $isActive: false) {
  $active: if($isActive==true, '-active', '');
  &.is-color-context-#{$colorContext} {
    &.is-color-#{$color} {
      background-color: CheckBox('background-' + $colorContext + '-' + $color + '-unchecked' + $active);
      box-shadow: 0 0 0 1px CheckBox('border-' + $colorContext + '-' + $color + '-unchecked' + $active);
      &.is-checked {
        background-color: CheckBox('background-' + $colorContext + '-' + $color + '-checked' + $active);
        box-shadow: 0 0 0 1px CheckBox('border-' + $colorContext + '-' + $color + '-checked' + $active);
        .check {
          svg {
            fill: CheckBox('check-' + $colorContext + '-' + $color);
          }
        }
      }
    }
  }
}

.wrapper {
  display: inline-flex;
  cursor: pointer;
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;

  &:active {
    + .checkbox {
      @include check-box-colors($color: 'default', $colorContext: 'bright', $isActive: true);
      @include check-box-colors($color: 'default', $colorContext: 'mid-tone', $isActive: true);
      @include check-box-colors($color: 'default', $colorContext: 'dark', $isActive: true);
    }
  }
}

.checkbox-wrapper {
  position: relative;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s linear;
  &.is-size-default {
    width: $space-m + $space-s;
    height: $space-m + $space-s;
    border-radius: $border-radius;
  }
  &.is-size-small {
    width: $space-m;
    height: $space-m;
    border-radius: $border-radius-sharp;
    .check {
      svg {
        width: $space-s * 2;
        height: $space-s * 2;
      }
    }
  }
  @include check-box-colors($color: 'default', $colorContext: 'bright');
  @include check-box-colors($color: 'default', $colorContext: 'mid-tone');
  @include check-box-colors($color: 'default', $colorContext: 'dark');
}

.icon-wrapper {
  position: absolute;
}
