@import '../../css/variables/all';
@import '../../css/functions/var-map';

.card {
  background: $neutral--light-4;
  border: 1px solid $neutral--light-4;
  border-radius: $border-radius;
  padding: 0;
  box-shadow: 0 3px 4px -2px Card(box-shadow-color);
  &.is-flat {
    border: 1px solid $border-hairline;
    box-shadow: none;
  }
  &.is-active {
    box-shadow: 0 0 0 4px Card(box-shadow-color-active), 0 3px 4px -2px Card(box-shadow-color);
  }
  &:hover {
    box-shadow: 0 0 0 1px Card(box-shadow-color-hover), 0 3px 4px -2px Card(box-shadow-color);
  }
  &.is-hovered,
  &.is-focused {
    box-shadow: 0 0 0 1px Card(box-shadow-color-hover), 0 3px 4px -2px Card(box-shadow-color);
  }
  &.is-hovered.is-active,
  &.is-focused.is-active {
    box-shadow: 0 0 5px 3px Card(box-shadow-color-focused), 0 0 0 4px Card(box-shadow-color-active),
      0 3px 4px -2px Card(box-shadow-color);
  }
}

.card-header-title {
  padding-bottom: $space-s;
}

.card-header {
  padding: $space-m $space-m 0px $space-m;
}

.card-footer {
  padding: 0px $space-m $space-m $space-m;
}

.card-content {
  padding: $space-m;
}

.card-footer-divider {
  border-top: 1px solid $border-hairline;
  margin-bottom: $space-m;
}
