@import '../variables/typography';

$typefaces-path: '../../assets/typefaces';

@font-face {
  font-family: 'proxima-nova';
  src: url('#{$typefaces-path}/proximanova-light-webfont.eot');
  src: url('#{$typefaces-path}/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$typefaces-path}/proximanova-light-webfont.woff2') format('woff2'),
    url('#{$typefaces-path}/proximanova-light-webfont.woff') format('woff'),
    url('#{$typefaces-path}/proximanova-light-webfont.ttf') format('truetype'),
    url('#{$typefaces-path}/ProximaNova-Light.otf') format('opentype'),
    url('#{$typefaces-path}/proximanova-light-webfont.svg#proxima-nova-regular') format('svg');
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('#{$typefaces-path}/proximanova-regular-webfont.eot');
  src: url('#{$typefaces-path}/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$typefaces-path}/proximanova-regular-webfont.woff2') format('woff2'),
    url('#{$typefaces-path}/proximanova-regular-webfont.woff') format('woff'),
    url('#{$typefaces-path}/proximanova-regular-webfont.ttf') format('truetype'),
    url('#{$typefaces-path}/ProximaNova-Regular.otf') format('opentype'),
    url('#{$typefaces-path}/proximanova-regular-webfont.svg#proxima-nova-regular') format('svg');
  font-weight: $font-weight-normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('#{$typefaces-path}/proximanova-bold-webfont.eot');
  src: url('#{$typefaces-path}/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('#{$typefaces-path}/proximanova-bold-webfont.woff2') format('woff2'),
    url('#{$typefaces-path}/proximanova-bold-webfont.woff') format('woff'),
    url('#{$typefaces-path}/proximanova-bold-webfont.ttf') format('truetype'),
    url('#{$typefaces-path}/ProximaNova-Bold.otf') format('opentype'),
    url('#{$typefaces-path}/proximanova-bold-webfont.svg#proxima-nova-bold') format('svg');
  font-weight: $font-weight-bold;
  font-style: normal;
}
