@import '../../css/variables/all.scss';
@import '../../css/functions/var-map.scss';
@import '../../css/mixins/input';

.action-padding {
  padding-left: $space-xs;
}

.row-actions-menu {
  display: inline-flex;
  ul {
    padding: $space-s;
  }
}

.row-actions-list {
  border-radius: $border-radius;
  background-color: $neutral--light-4;
  &.is-contrast {
    background-color: $neutral--dark-1;
  }
}
