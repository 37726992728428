@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/mixins/breakpoints';

$left-col-flex: 0 0 400px;

.cargo-details-header {
  border-bottom: 1px solid $border-hairline;
}

.list-column {
  flex: $left-col-flex;
}

.cargo-details-list-container {
  flex: $left-col-flex;
}

.cargo-details-content {
  overflow: hidden;
}

.section-title-wrapper {
  position: relative;
}

.section-title-indicator {
  &::before {
    content: '■';
    position: absolute;
    height: 100%;
    left: -$space-xs;
  }
}

.list-section-heading {
  padding: $space-s $space-base-unit * 1.25;
}

.cargo-details-list {
  .list-item {
    cursor: pointer;
    padding: $space-base-unit $space-base-unit * 1.25;
    outline: 0;

    &:hover,
    &:focus,
    &.is-selected {
      background: CargoDetails('drilldown-selected-background');
    }
  }
}

.cargo-details-values-dot {
  background-color: #ccc;
  border-radius: 50%;
  height: 5px;
  width: 5px;
}

.cargo-details {
  display: flex;
  flex-direction: column;
  @include for-desktop-up {
    flex-direction: row;
  }
}

.cargo-details-left-column {
  @include for-desktop-up {
    margin-right: $space-l;
    max-width: 50%;
  }
}

.cargo-details-right-column {
  @include for-tablet-portrait-up {
    min-width: $space-container-narrow;
  }
}
