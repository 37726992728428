$base-font: 'proxima-nova', 'Lucida Grande', Arial, sans-serif;

$type-wide-desktop-base: 1.3rem;
$type-wide-desktop-scale: 1.25;
$type-wide-desktop-xs: 1rem;
$type-wide-desktop-s: $type-wide-desktop-base;
$type-wide-desktop-m: $type-wide-desktop-s * $type-wide-desktop-scale;
$type-wide-desktop-l: $type-wide-desktop-m * $type-wide-desktop-scale;
$type-wide-desktop-xl: $type-wide-desktop-l * $type-wide-desktop-scale;
$type-wide-desktop-xxl: $type-wide-desktop-xl * $type-wide-desktop-scale;
$type-wide-desktop-xxxl: $type-wide-desktop-xxl * $type-wide-desktop-scale;

$type-desktop-base: 1.3rem;
$type-desktop-scale: 1.2;
$type-desktop-xs: 1rem;
$type-desktop-s: $type-desktop-base;
$type-desktop-m: $type-desktop-s * $type-desktop-scale;
$type-desktop-l: $type-desktop-m * $type-desktop-scale;
$type-desktop-xl: $type-desktop-l * $type-desktop-scale;
$type-desktop-xxl: $type-desktop-xl * $type-desktop-scale;
$type-desktop-xxxl: $type-desktop-xxl * $type-desktop-scale;

$type-tablet-base: 1.3rem;
$type-tablet-scale: 1.2;
$type-tablet-xs: 1rem;
$type-tablet-s: $type-tablet-base;
$type-tablet-m: $type-tablet-s * $type-tablet-scale;
$type-tablet-l: $type-tablet-m * $type-tablet-scale;
$type-tablet-xl: $type-tablet-l * $type-tablet-scale;
$type-tablet-xxl: $type-tablet-xl * $type-tablet-scale;
$type-tablet-xxxl: $type-tablet-xxl * $type-tablet-scale;

$type-mobile-base: 1.3rem;
$type-mobile-scale: 1.125;
$type-mobile-xs: 1rem;
$type-mobile-s: $type-mobile-base;
$type-mobile-m: $type-mobile-s * $type-mobile-scale;
$type-mobile-l: $type-mobile-m * $type-mobile-scale;
$type-mobile-xl: $type-mobile-l * $type-mobile-scale;
$type-mobile-xxl: $type-mobile-xl * $type-mobile-scale;
$type-mobile-xxxl: $type-mobile-xxl * $type-mobile-scale;

$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 600;
