@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/z-layers';

.org-selector-wrapper {
  position: fixed;
  bottom: 0;
  left: $space-xl;
  z-index: z('base') + 1;
  padding: $space-m;
  &.has-top-bar {
    top: $space-top-bar-height + $space-xl;
  }
}
