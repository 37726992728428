@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers';

$cal-width: $space-l * 11;
$cal-height: $space-xl * 5;
$cal-height-margin: $cal-height + $space-l;

div {
  &:global(.react-daterange-picker__calendar) {
    min-height: $cal-height;
  }
}

.wrapper {
  @include body;
  @include input-small;
  display: inline-block;
  outline: none;
  border-color: TextInput('default-regular-border');
  border-style: solid;
  border-width: $border-width;
  border-radius: $border-radius;
  color: TextInput('default-regular-text');
  padding: $space-inset-stretch-m;
  white-space: nowrap;
  background-color: $neutral--light-4;
  &:not(.is-disabled) {
    &.is-focused {
      border-color: TextInput('item-focus-border');
      box-shadow: 0 0 0 0.2rem TextInput('item-focus-border');
    }
    &.has-errors {
      border-color: TextInput('danger-regular-border');
      box-shadow: 0 0 0 0.2rem TextInput('danger-regular-border');
    }
  }
}

.input-wrapper {
  display: flex;
  > div,
  > input {
    @include input-small;
    border: 0;
    padding: 0;
    text-align: center;
    outline: 0;
    &::placeholder {
      color: TextInput('default-regular-placeholder');
    }
    &:focus {
      background: TextInput('item-focus-background');
      color: TextInput('item-focus-text');
      border-radius: $border-radius;
    }
  }

  select {
    @include input-small;
    border: 0;
    padding: 0;
    text-align: center;
    outline: 0;
    &:focus {
      box-shadow: 0 0 0 0.1rem TextInput('item-focus-border');
    }
  }

  input {
    outline: 0;
    &:invalid {
      background: none;
      &:focus {
        background: TextInput('item-focus-background');
        color: TextInput('item-focus-text');
        border-radius: $border-radius;
      }
    }
  }

  button {
    outline: none;
  }
}

.calendar-wrapper {
  position: fixed;
  min-height: $cal-height;
  min-width: $cal-width;
  width: $cal-width;
  border-color: TextInput('default-regular-border');
  border-style: solid;
  border-width: $border-width;
  border-radius: $border-radius;
  color: TextInput('default-regular-text');
  padding: $space-m;
  box-shadow: 0 2px 8px transparentize($neutral--dark, 0.4);
  @include for-tablet-portrait-down {
    width: 100%;
    left: 0;
    top: calc(100% - #{$cal-height-margin});
  }

  abbr {
    text-decoration: none;
    @include fine-print;
  }
}

.calendar-tile {
  padding: $space-m;
  &:hover {
    background: Calendar('tile-hover') !important;
  }
  &:global(.react-calendar__tile--active.react-calendar__tile--range) {
    background: Calendar('tile-in-range');
    color: initial;
  }
  &:global(.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeBothEnds) {
    background: Calendar('tile-selected-background');
    color: Calendar('tile-selected-color');
    border-radius: 0;
    border-top-left-radius: $border-radius-round;
    border-bottom-left-radius: $border-radius-round;
  }
  &:global(.react-calendar__tile--now.react-calendar__month-view__days__day) {
    position: relative;
    background: transparent;
    &::before {
      content: '';
      width: $space-l;
      height: $space-l;
      position: absolute;
      top: calc(50% - #{$space-l} / 2);
      left: calc(50% - #{$space-l} / 2);
      z-index: z('base');
      border-radius: 100%;
      background: Calendar('tile-current-background');
    }
    abbr {
      color: Calendar('tile-current-color');
      position: relative;
      z-index: z('base') + 1;
    }
  }
  &:global(.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day) {
    background: Calendar('tile-selected-background');
    border-top-left-radius: $border-radius-round;
    border-bottom-left-radius: $border-radius-round;
  }
  &:global(.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day) {
    background: Calendar('tile-selected-background');
    border-top-right-radius: $border-radius-round;
    border-bottom-right-radius: $border-radius-round;
  }
  &:global(.react-calendar__tile--now.react-calendar__tile--hover) {
    background: Calendar('tile-in-range');
  }
  &:global(.react-calendar__tile--active),
  &:global(.react-calendar__tile--hasActive.react-calendar__month-view__days__day) {
    background: Calendar('tile-selected-background');
    color: Calendar('tile-selected-color');
  }
  &:global(.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day) {
    color: Calendar('tile-selected-color');
    border-top-left-radius: $border-radius-round;
    border-bottom-left-radius: $border-radius-round;
  }
  &:global(.react-calendar__tile--hasActive.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day) {
    color: Calendar('tile-selected-color');
    border-top-right-radius: $border-radius-round;
    border-bottom-right-radius: $border-radius-round;
  }
  &:global(.react-calendar__month-view__days__day--weekend) {
    color: Calendar('tile-weekend-color');
  }

  &:global(.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day) {
    border-radius: 0;
  }

  &:global(.react-calendar__tile--now.react-calendar__century-view__decades__decade:not(.react-calendar__tile--range)),
  &:global(.react-calendar__tile--now.react-calendar__decade-view__years__year:not(.react-calendar__tile--range)),
  &:global(.react-calendar__tile--now.react-calendar__year-view__months__month:not(.react-calendar__tile--range)) {
    position: relative;
    background: transparent;
    &::before {
      content: '';
      width: $space-s;
      height: $space-s;
      position: absolute;
      top: $space-s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      border-radius: 100%;
      background: Calendar('tile-current-background');
    }
  }
  &:global(.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__century-view__decades__decade),
  &:global(.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__decade-view__years__year),
  &:global(.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__year-view__months__month),
  &:global(.react-calendar__tile--now.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day) {
    background: Calendar('tile-in-range');
  }
  &:global(.react-calendar__tile--now.react-calendar__century-view__decades__decade.react-calendar__tile--hover.react-calendar__tile--hoverBothEnds),
  &:global(.react-calendar__tile--now.react-calendar__decade-view__years__year.react-calendar__tile--hover.react-calendar__tile--hoverBothEnds),
  &:global(.react-calendar__tile--now.react-calendar__year-view__months__month.react-calendar__tile--hover.react-calendar__tile--hoverBothEnds) {
    background: Calendar('tile-in-range');
  }
  &:global(.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade),
  &:global(.react-calendar__tile--hasActive.react-calendar__decade-view__years__year),
  &:global(.react-calendar__tile--hasActive.react-calendar__year-view__months__month) {
    background: Calendar('tile-in-range');
  }
  &:global(.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade),
  &:global(.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year),
  &:global(.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month) {
    background: Calendar('tile-in-range');
  }
}
