@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers.scss';

.popover {
  z-index: z('dropdown');
}

.menu-button {
  border: unset;
  background-color: transparent;
  svg {
    fill: MainMenu('Background') !important;
  }
}

.back-drop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vh;
  height: 100vh;
  background-color: MainMenu('backdrop');
}

.popover-container {
  background-color: MainMenu('background');
  border-radius: $border-radius;
  box-shadow: 0 2px 8px transparentize($neutral--dark-2, 0.7);
  margin-top: $space-xs;
  overflow: hidden;
  transform-origin: top right;
  &.animate-up {
    transform-origin: bottom right;
  }
}

.label-button {
  background-color: transparent;
  border: unset;
  &.no-padding {
    padding: 0px;
  }
}
