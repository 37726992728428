@import '../../css/variables/all';
@import '../../css/functions/var-map';

.table {
  table-layout: fixed;
  &.is-full-width {
    width: 100%;
  }
  &.is-cell-size-dense {
    .cell {
      padding: $space-m;
      &.is-side-header {
        padding-left: 0;
      }
    }
  }
  &.is-cell-size-default {
    .cell {
      padding: $space-inset-stretch-l;
    }
  }
}

.row {
  border-bottom: 1px solid Table('separator');
  transition: background-color 0.5s ease-out;
  th:not(:first-child),
  td:not(:first-child) {
    border-left: 1px solid Table('lightSeparator');
  }
  td:first-child {
    width: 'auto';
  }
  &.is-side-header-auto {
    td:first-child {
      width: 'auto';
    }
  }
  &.is-side-header-narrow {
    td:first-child {
      width: 25%;
    }
  }
  &.is-side-header-wide {
    td:first-child {
      width: 50%;
    }
  }
  &:last-of-type {
    border-bottom: 0;
  }
}

.cell {
  text-align: center;
  vertical-align: middle;
  &.is-side-header:not(.is-top-header) {
    text-align: left;
  }
  &.is-top-header {
    vertical-align: top;
  }
}
