@import '~tnx-ui/src/css/variables/all';

.replied-at-wrapper {
  margin-top: $space-s;
}
.replied-date {
  height: $space-s + $space-xs;
}
.small-price-wrapper {
  height: $space-s + $space-xs;
}
