@import '../../css/variables/all';
@import '../../css/functions/var-map';

$arrow-length: $space-l + $space-s;
$arrow-width: $space-s - 2;

.arrow-container {
  color: Icon('default');
  .arrow-up,
  .arrow-up::after {
    display: block;
    box-sizing: border-box;
    width: $arrow-width;
  }
  .arrow-up {
    position: relative;
    transform: scale(1.5);
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    box-shadow: inset 0 0 0 2px;
    height: $arrow-length;
  }
  .arrow-up::after {
    content: '';
    position: absolute;
    top: -$arrow-width;
    left: -$arrow-width * 0.5;
    width: 0;
    height: 0;
    border-left: $space-xs solid transparent;
    border-right: $space-xs solid transparent;
    border-bottom: $space-s solid Icon('default');
  }
  &.is-color-light {
    color: Icon('light');
    .arrow-up::after {
      border-bottom: $space-s solid Icon('light');
    }
  }
  &.is-direction-down {
    transform: rotate(180deg);
  }
  &.is-direction-right {
    transform: rotate(90deg);
  }
  &.is-direction-left {
    transform: rotate(-90deg);
  }
  &.is-size-extra-small {
    .arrow-up {
      transform: scale(0.7);
    }
  }
  &.is-size-small {
    .arrow-up {
      transform: scale(1);
    }
  }
  &.is-size-large {
    .arrow-up {
      transform: scale(3);
    }
  }
  &.is-centered {
    .arrow-up {
      margin: auto;
    }
  }
}
