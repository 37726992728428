.level {
  align-items: center;
  display: flex;
  justify-content: space-between;
  &.has-wrap {
    flex-wrap: wrap;
  }
  &.is-alignment-center {
    justify-content: center;
  }
  &.is-alignment-left {
    justify-content: flex-start;
  }
  &.is-alignment-right {
    justify-content: flex-end;
  }
  &.is-vertical-alignment-bottom {
    align-items: flex-end;
  }
  &.is-vertical-alignment-center {
    align-items: center;
  }
  &.is-vertical-alignment-top {
    align-items: flex-start;
  }
}

.level-item {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
}

.level-left {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;

  & + .level-right {
    flex-grow: 0;

    & .level-item {
      flex-grow: 0;
    }
  }
}

.level-right {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;

  & .level-item {
    flex-grow: 0;
  }
}
