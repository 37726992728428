@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/mixins/input';

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  background: $neutral--light-2;
  width: 100%;
  @include for-tablet-portrait-up {
    max-width: $space-m * 40;
    width: $space-m * 40;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $space-l + $space-s;
  padding: $space-inset-squish-m;
  border-bottom: 1px solid $border-dark-hairline;
}
.header-title {
  flex-grow: 1;
  white-space: nowrap;
}
.clear-button {
  flex-grow: 0;
  width: auto;
}

.body {
  padding: $space-m $space-m $space-s $space-m;
  display: flex;
  flex-wrap: wrap;
}
.footer {
  border-top: 1px solid $border-dark-hairline;
  padding: $space-inset-squish-m;
}

.filter-wrapper {
  padding: $space-inset-stretch-s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid Filters('filter-border-bottom-color');
  background-color: Filters('filter-background-color');
  outline: 0;
  &:last-of-type {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  @include for-tablet-portrait-down {
    &:not(:last-child) {
      border-bottom: 1px solid $border-dark-hairline;
    }
    padding: $space-m;
  }
}

.actions {
  display: flex;
  flex-shrink: 0;
  @include for-tablet-portrait-down {
    margin-left: $space-m;
  }
}

.add-filter-action {
  width: 100%;
  padding: $space-s;
  border-bottom: 1px solid Filters('filter-border-bottom-color');
  background-color: Filters('filter-background-color');
}

.filter {
  justify-content: flex-start;
  flex-grow: 1;
  display: flex;
  border-right: 0;
  @include for-tablet-portrait-down {
    display: block;
  }
}

.filter-input {
  margin-right: $space-s;
  @include for-tablet-portrait-down {
    margin-bottom: $space-s;
  }
}

.full-input {
  flex-grow: 1;
}

.short-input {
  min-width: $space-l;
  max-width: $space-xl * 2;
  @include for-tablet-portrait-down {
    flex-grow: 1;
    max-width: 100%;
    margin-right: $space-s !important;
  }
}

.filter-action {
  outline: 0;
  background: Filters('filter-trigger-background-color');
  border-color: Filters('filter-trigger-background-color');
  svg {
    fill: Filters('filter-trigger-icon-color');
  }
  &:hover:enabled,
  &:focus:enabled {
    background-color: Filters('filter-trigger-background-color-hover');
    border-color: Filters('filter-trigger-background-color-hover');
    svg {
      fill: Filters('filter-trigger-icon-color-hover');
    }
  }
  &:focus:enabled {
    background-color: Filters('filter-trigger-background-color-hover');
    border-color: Filters('filter-trigger-background-color-hover');
    svg {
      fill: Filters('filter-trigger-icon-color-focus');
    }
  }
  &:active:enabled {
    background-color: Filters('filter-trigger-background-color-hover');
    svg {
      fill: Filters('filter-trigger-icon-color-active');
    }
  }
}

.tooltip {
  max-width: $space-xl * 4;
  margin-bottom: $space-m;
}

.draw-map-button {
  @include for-tablet-portrait-down {
    width: 100%;
  }
}

.location-input {
  background-color: TextInput('default-regular-background');
  // border: 1px solid TextInput('default-regular-border');
  border-radius: $border-radius;
  width: 100%;
  margin-right: $space-s;
  &.is-focused {
    border: 1px solid TextInput('default-focus-border');
    box-shadow: 0 0 0 1px TextInput('default-focus-border');
  }
}

.is-start svg {
  fill: GoogleMap('region-start-color') !important;
}
.is-end svg {
  fill: GoogleMap('region-end-color') !important;
}

.laneway-level {
  @include for-tablet-portrait-down {
    flex-direction: column;
    .location-input {
      margin-bottom: $space-m;
    }
    .laneway-arrow {
      margin-bottom: $space-m;
      transform: rotate(90deg);
    }
  }
}

.overbid-input {
  width: $space-xl + $space-l;
}

.carrier-bid-type-wrapper {
  @include for-tablet-portrait-down {
    margin: $space-stack-s;
  }
}

div.time-filter {
  display: flex;
  align-items: center;
  height: 38px;
  border-color: $neutral;
}
