.key-value-pair {
  display: flex;

  &.inline-reverse {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &.stack-key-bottom,
  &.stack-key-top {
    flex-direction: column;
  }

  &.stack-key-top > *:first-child {
    order: 1;
  }
}
