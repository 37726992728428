@import '../../css/variables/all.scss';
@import '../../css/functions/var-map.scss';
@import '../../css/mixins/input';
@import '../../css/functions/z-layers';

.future-filter > *:not(:last-child) {
  margin-right: $space-s;
}
.future-filter-count {
  width: $space-l * 2;
}
.future-filter-unit {
  position: relative;
  select {
    @include input;
    padding: $space-inset-stretch-s;
    border-radius: $border-radius;
    border-width: $border-width;
    border-style: solid;
    border-color: TextInput('default-regular-border');
    transition: box-shadow 0.15s ease-out;
    height: $space-l + $space-xs;
    appearance: none;
    width: 100%;
    margin-right: 15px;
    box-shadow: 0 0 0 0 transparent;
    &:focus {
      border-color: TextInput('default-focus-border');
      box-shadow: 0 0 0 1px TextInput('default-focus-border');
      outline: none;
    }
    &:focus + .select-arrow svg {
      fill: TextInput('default-regular-text');
    }
  }
  .select-arrow {
    position: absolute;
    top: $space-s + $space-xs * 0.5;
    right: $space-xs;
    pointer-events: none;
    svg {
      fill: TextInput('default-regular-placeholder');
    }
  } 
}