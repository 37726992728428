@import '~tnx-ui/src/css/variables/all.scss';

.tender-outline-wrapper {
  display: flex;
}
.tender-outline-content {
  box-sizing: border-box;
  &.left {
    padding: $space-m + $space-s $space-m $space-m + $space-s $space-s;
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
  }
  &.right {
    padding: $space-m + $space-s $space-m $space-m + $space-s 0;
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    align-items: center;
  }
}
