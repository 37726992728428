@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/mixins/breakpoints.scss';
@import '~tnx-ui/src/css/functions/z-layers';

.wrapper-open {
  position: relative;
  z-index: z('base') + 1;
}
.wrapper-closed {
  z-index: z('base') + 0;
}
.sort-level {
  @include for-tablet-portrait-down {
    flex-direction: column;
  }
  justify-content: flex-end;
}
.sort-select {
  min-width: $space-default * 12;
  margin-right: $space-s;
  > div {
    border-radius: $border-radius;
  }
  @include for-tablet-portrait-down {
    margin-right: 0px;
    margin-bottom: $space-m;
  }
  div[class$='-option'] {
    padding-top: $space-s * 1.5;
    padding-bottom: $space-s * 1.5;
  }
}
.open {
  margin-right: -100px;
  @include for-tablet-portrait-down {
    margin-right: -200px;
  }
}
.closed {
  margin-right: -$space-s;
  @include for-tablet-portrait-down {
    margin-right: 0px;
  }
}

.places-input-container {
  position: relative;
}
.button-overlay {
  position: absolute;
  top: 6px;
  right: 8px;
  background: $neutral--light-4;
  opacity: 1;
}

.sort-label {
  display: none;
  @include for-tablet-portrait-up {
    display: block;
  }
}
