@import '../../css/variables/all.scss';
@import '../../css/functions/var-map.scss';

.wrapper {
  width: 100%;
  margin-bottom: $space-xs;
  &.variant-light {
    border: 1px solid $neutral--light;
    border-radius: $border-radius;
    box-shadow: 0px 0.5px 1px $neutral, 0px 1px 2px $neutral, 0px 2px 3px $neutral--light;
  }
}

.container {
  width: 100%;
  padding: $space-m 0px;
  background-color: Accordion('content-background');
  &.variant-light {
    background-color: $neutral--light-4;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

  }
}

.has-static-component {
  padding-top: 0;
}

.left-item {
  flex-shrink: 1 !important;
}

.toggle-button {
  width: 100%;
  padding: $space-m $space-m;
  border-bottom: 1px solid $border-dark-hairline;
  background: $neutral--light-4;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  cursor: pointer;
  background-color: Accordion('header-background-collapsed');
  &.is-expanded {
    background-color: Accordion('header-background-expanded');
  }
  &.variant-light {
    border-radius: $border-radius;
    border-bottom: unset;  
    &.has-static-component {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-bottom: 1px solid $border-dark-hairline;
    }
    &.is-expanded {
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      background-color: $neutral--light-4;
      border-bottom: 1px solid $border-dark-hairline;
    }
  }
}

