@import '../mixins/typography-base';

html {
  font-size: 62.5%;
}

body {
  @include type-primary-regular;
  @include font-size-m;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
