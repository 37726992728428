@import '../../css/variables/all';

.scroll-more {
  width: 100%;
  text-align: center;
  padding: $space-m;
}

.scroll-more-action {
  cursor: pointer;
}
