@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';

.waiting-image {
  width: 100%;
  max-height: 40vh;
  margin-top: -$space-l * 2;
  @include for-phone-only {
    width: 100%;
    height: 60vh;
    transform-origin: 50% 50%;
    transform: scale(1.5);
  }
}
