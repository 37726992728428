@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers.scss';

.dialog-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: z('overlay');
  background-color: Box('overlay-background');
}

.dialog {
  position: absolute;
  overflow: hidden;
  background: Box('background');
  outline: none;
  border-radius: $border-radius-round;
  box-shadow: 0 2px 8px transparentize($neutral--dark-2, 0.7);
  margin: $space-m;
}
