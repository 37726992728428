@import '~tnx-ui/src/css/variables/all';
.access-denied {
  opacity: 0.8;
  pointer-events: none;

  button {
    opacity: 0.5;
  }
}
.tooltip {
  padding: $space-s + $space-xs;
  margin-top: -$space-s;
}
