@import '../../css/variables/all';

// using !important to overwrite 3rd party library default styles
.file-upload {
  border: 1px solid $border-hairline !important;
  > div {
    margin-left: $space-s;
  }
  span {
    text-decoration: none !important;
  }
}

.preview {
  height: $space-xl * 2;
  max-width: 100%;
}
