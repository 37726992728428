@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/functions/z-layers';
@import '~tnx-ui/src/css/mixins/breakpoints.scss';

.view-switch-container {
  position: fixed;
  z-index: z('base') + 9;
  bottom: $space-l;
  @include for-tablet-portrait-down {
    bottom: $space-top-bar-height + $space-xl;
    &.has-small-margin {
      bottom: $space-top-bar-height;
    }
  }
  left: 50%;
  transform: translateX(-50%);
}

[data-search='open'] {
  .view-switch-container {
    z-index: z('base');
  }
}

.view-switch-button {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
