@import '../../css/variables/all';
@import '../../css/functions/var-map';

.input-container {
  position: relative;
}

.color-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: $space-s;
  width: $space-m + $space-s;
  height: $space-m + $space-s;
  border-radius: $border-radius;
  cursor: pointer;
}

.color-box-empty {
  background: linear-gradient(to top right, #ffffff calc(50% - 1px), $danger, #ffffff calc(50% + 1px));
  border: 1px solid $danger;
}

.text-input {
  padding-left: $space-m * 2 + $space-s;
}
