@use "sass:math";

@import '../../css/variables';
@import '../../css/mixins';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/functions/z-layers';

@mixin ellipsis {
  white-space: nowrap;
  p,
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

$favorite-container-width: $space-l + $space-s;
$number-of-column: 5;
$favorite-shift: math.div($favorite-container-width, $number-of-column);

.favorite {
  width: $favorite-container-width;
}

.from {
  flex-grow: 1;
  width: calc(17% - #{$favorite-shift});
  padding-right: $space-s;
  @include ellipsis;
}

.to {
  flex-grow: 1;
  width: calc(17% - #{$favorite-shift});
  padding-right: $space-s;
  @include ellipsis;
}

.stops {
  flex-grow: 1;
  width: calc(5% - #{$favorite-shift});
  display: flex;
  justify-content: center;
}

.displayed-services {
  flex-grow: 1;
  width: calc(35% - #{$favorite-shift});
  padding: 0px $space-s;
  > div {
    padding-right: $space-s;
  }

  .services-right {
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: 35%;
  }
}

.price {
  flex-grow: 1;
  width: calc(13% - #{$favorite-shift});
  text-align: right;
}
.created-at {
  flex-grow: 1;
  width: calc(13% - #{$favorite-shift});
  text-align: right;
  @include ellipsis;
}

.link {
  text-decoration: none;
  width: 100%;
}

.separator {
  min-width: $space-xl;
  &:not(:last-child) {
    padding-right: $space-s;
    border-right: 1px solid $border-dark-hairline;
  }
}

.measures-tooltip {
  margin-left: $space-xl;
}

$list-item-min-height: 59px;

.tender {
  width: 100%;
  position: relative;
  min-height: $list-item-min-height;
  padding: $space-inset-stretch-m;
  border-bottom: $border-hairline 1px solid;
  &:not(:empty).is-route-selected {
    background-color: List(passive-selection);
  }
  &:not(:empty):hover {
    background-color: List(hover);
  }
}

.map-directions-toggle-wrapper {
  position: absolute;
  height: calc(100% + 1px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $space-l * 2 + $space-m;
  padding: 0 $space-m;
  border: 0;
  border-radius: 0;
  outline: 0;
  right: -($space-l * 2 + $space-m);
  z-index: z('base') + 1;
  overflow: hidden;
  cursor: initial;
}

.map-directions-toggle {
  cursor: pointer;
  user-select: none;
  height: $space-l + $space-s;
  width: $space-l + $space-s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0;
  outline: 0;
  border-radius: 100%;
  background-color: $neutral--light-4;
  user-select: none;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    background-color: List(hover);
  }
  &.is-selected {
    background-color: Button(primary-regular-background);
    svg {
      fill: Button(primary-regular-text);
    }
  }
}

.tender-container {
  &.is-rejected {
    opacity: 0.5;
  }
  &.is-expired {
    opacity: 0.5;
  }
}
