@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers';

.wrapper {
  width: 100%;
  height: $space-top-bar-height;
  top: 0;
  z-index: z('navbar');
  padding: $space-inset-m;
  &.is-color-default {
    background-color: TopBar('background-light');
    border-bottom: 1px solid TopBar('border-bottom-light');
    p,
    span {
      color: TopBar('color-light');
    }
    .left-content {
      svg {
        fill: TopBar('color-light');
      }
    }
  }

  &.is-color-dark {
    background-color: TopBar('background-dark');
    border-bottom: 1px solid TopBar('border-bottom-dark');
    p,
    span {
      color: TopBar('color-dark');
    }
    .left-content {
      svg {
        fill: TopBar('color-dark');
      }
    }
  }

  &.is-color-transparent {
    background-color: transparent;
    border-bottom: 1px solid transparent;
  }

  &.is-color-bright {
    background-color: TopBar('background-bright');
  }

  &.is-absolute {
    position: absolute;
  }

  &.is-sticky {
    position: sticky;
  }

  &.is-responsive {
    @include for-tablet-landscape-down {
      display: flex;
      flex-flow: column-reverse;
      height: auto;
      padding: 0;
      .left-content {
        width: 100%;
        padding: $space-s;
      }
      .right-content {
        margin-bottom: $space-s;
        width: 100%;
      }
    }
  }
}
