@use 'sass:math';

@import '../../css/variables/all.scss';
@import '../../css/functions/var-map.scss';
@import '../../css/mixins/input';
@import '../../css/functions/z-layers';

$base: z('base');
$col-header-height: $space-s * 11;

$multishadow: 0 0.6px 1.1px rgba(0, 0, 0, 0.041), 0 1.3px 2.6px rgba(0, 0, 0, 0.051), 0 2.5px 4.9px rgba(0, 0, 0, 0.057),
  0 4.5px 8.7px rgba(0, 0, 0, 0.063), 0 8.4px 16.3px rgba(0, 0, 0, 0.073), 0 20px 39px rgba(0, 0, 0, 0.1);

.wrapper {
  isolation: isolate;
  position: relative;
  &.is-body-scroll {
    height: 100%;
    overflow: hidden;
  }
}

.main-header {
  position: sticky;
  top: 0px;
  z-index: $base + 5;
  background-color: DataTable('header-background-light');
  height: $space-m * 3;
}
.main-header-left {
  h1 {
    padding: $space-m 0;
  }
}
.main-header-right {
  position: absolute;
  top: $space-s;
  right: 0;
}

.main-header-actions-divider {
  padding-right: $space-m;
  margin-right: $space-m;
  border-right: 1.5px solid $border-dark-hairline;
}

.custom-action-component {
  margin-left: $space-m;
}

.table-wrapper {
  width: calc(100vw - 45px);
  overflow-x: clip;
}
.table-wrapper-centered {
  width: unset;
}

.column-headers {
  position: sticky;
  top: $space-m * 3;
  z-index: $base + 4;
}
.column-headers .thead {
  position: relative;
}
.column-header {
  min-height: $col-header-height;
  display: flex;
  flex-flow: row nowrap;
}
.column-header-checkbox {
  padding: ($space-s * 1.2) 0 0 $space-m;
  background-color: DataTable('header-background');
  border-radius: $border-radius 0 0 0;
}

.column-header-content,
.column-header-content-reverse {
  flex-grow: 2;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  .column-header-menu {
    grid-area: column-header-menu;
    margin-top: $space-xs;
    position: relative;
  }
  .column-header-menu-icon {
    svg {
      fill: none;
    }
  }
  .popover {
    padding: $space-m;
    width: max-content;
  }
  .popover-small {
    min-width: $space-l * 6;
  }
  li.column-header-menu-item:hover * {
    color: DataTable('border-color');
    fill: DataTable('border-color');
  }
  li:only-child {
    margin-bottom: initial;
  }
  .column-header-menu:hover::before {
    content: '';
    position: absolute;
    width: $space-m + $space-s;
    height: $space-m + $space-s;
    border-radius: $space-m;
    background-color: DataTable('menu-icon-background-hover');
  }
}
.column-header-content {
  grid-template-columns: auto 1fr $space-l;
  grid-template-areas:
    'column-header-title column-header-sort column-header-menu'
    'column-header-filter column-header-filter column-header-filter';
  .column-header-menu {
    padding-right: $space-m;
    .column-header-menu-icon {
      position: relative;
      top: $space-m;
    }
  }
  .column-header-menu:hover::before {
    top: $space-xs;
    left: -$space-xs * 0.55;
  }
  .column-header-sort {
    position: absolute;
    top: $space-xs * 1.4;
    left: -$space-m;
  }
}
.column-header-content-reverse {
  grid-template-columns: $space-l 1fr auto;
  grid-template-areas:
    'column-header-menu column-header-sort column-header-title'
    'column-header-filter column-header-filter column-header-filter';
  .column-header-menu {
    padding-left: $space-l;
    .column-header-menu-icon {
      position: relative;
      top: $space-m;
      left: -$space-m * 1.2;
    }
  }
  .column-header-menu:hover::before {
    top: $space-xs;
    left: $space-m * 0.63;
  }
  .column-header-sort {
    position: absolute;
    top: $space-xs * 1.3;
    right: -$space-s * 1.5;
  }
}

.th:hover .column-header-menu-icon svg {
  fill: inherit;
}

.column-header-title {
  grid-area: column-header-title;
  height: $space-l;
  padding: $space-s $space-m;
  margin-top: $space-xs;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  & * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.column-header-sort {
  grid-area: column-header-sort;
  margin-top: $space-xs;
}
.column-header-filter {
  grid-area: column-header-filter;
  padding: $space-s $space-m;
}

.sub-filter-icon {
  position: absolute;
  bottom: $space-m * 1.57;
  left: $space-m * 1.2;
}

.sidebar-wrapper {
  position: sticky;
  top: $space-s * 6;
  z-index: $base + 5;
}
.sidebar-title {
  padding: $space-m;
}
.sidebar-close {
  position: absolute;
  top: $space-s;
  right: $space-s;
  cursor: pointer;
}
.sidebar {
  position: absolute;
  top: 0px;
  bottom: 0px;
  min-width: $space-l * 6.75;
  height: fit-content;
  border: 1px solid DataTable('border-neutral');
  border-radius: 0 $space-s $space-s 0;
  background-color: DataTable('row-background');
  z-index: $base + 5;
  header {
    position: relative;
    border-bottom: 1px solid DataTable('border-neutral');
  }
  ul {
    padding: $space-m;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: -$space-s;
    bottom: 0;
    width: $space-s;
    background: linear-gradient(
      270deg,
      #e6e6e6 0%,
      rgba(248, 247, 247, 0.966667) 14.06%,
      rgba(239, 239, 239, 0.49) 27.34%,
      rgba(255, 255, 255, 0) 117.19%
    );
  }
}

.datatable {
  border-spacing: 0;
  .th {
    color: DataTable('header-text');
    background-color: DataTable('header-background');
    flex-shrink: 0;
    position: relative;
    z-index: $base + 1;
    &.column-header-end {
      width: $space-m * 8;
    }
    &.column-header-end-short {
      width: $space-m * 4;
    }
    &.row-actions-header {
      border-top-right-radius: $border-radius;
      border-left: 1px solid $border-dark-hairline;
      position: absolute;
      z-index: $base + 2;
      width: $space-m * 8;
      height: $col-header-height;
      top: 0;
      & .header-action-column-button {
        position: absolute;
        top: $space-xs;
        right: $space-xs;
      }
    }
    &:hover {
      z-index: $base + 2;
    }
    & .resizer {
      z-index: $base + 3;
      position: absolute;
      right: 0px;
      top: 15%;
      width: $space-s;
      height: 75%;
      background: rgba(DataTable('header-divider'), 0);
      &.is-last-col {
        right: $space-xs;
      }
    }
    & .resizer::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      border-radius: $space-xs * 0.5;
      background: DataTable('header-divider');
    }
    & .resizer:hover::after,
    & .resizer.is-resizing::after {
      background: DataTable('border-color');
      width: $space-xs * 0.5;
      transform: translateX(50%);
    }
    & .resizer.is-resizing::after {
      width: math.div($space-s, 3);
    }
    &:first-child:is(.is-top-header)::after,
    &:is(.is-dragging)::after {
      display: none;
    }
    &:first-child {
      border-top-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      user-select: none;
    }
    &.is-dragging {
      cursor: move;
      opacity: 1;
    }
    &.is-pinned {
      position: sticky;
      z-index: $base + 8;
    }
    &.is-top-header {
      z-index: $base + 9;
    }
    &:not(:last-child):hover {
      background-color: DataTable('header-background-hover');
    }
    &:hover .column-header-filter {
      background-color: DataTable('header-background');
    }
    &.is-dragging .column-header {
      background-color: DataTable('header-background-light');
    }
  }

  .indeterminate {
    margin-top: $space-xs;
  }
}

.table-body {
  position: sticky;
  top: $space-m * 8.5;
}

.table-body .tr,
.td,
.row-actions-cell {
  background-color: DataTable('row-background');
  &.selected,
  &.selected .td {
    background-color: DataTable('row-background-selected');
  }
  &:hover,
  &:hover .td {
    background-color: DataTable('row-background-hover');
  }
  &.is-on-click-disabled {
    cursor: not-allowed;
  }

  .td:not(:last-child) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.td .row-checkbox {
  margin: -$space-m;
  margin-right: 0;
  padding: $space-m;
  float: left;
}

.td {
  padding: $space-m;
  border-bottom: 1.4px solid DataTable('row-border');
  color: DataTable('row-text');
  vertical-align: middle;
  &.is-pinned {
    z-index: $base + 4;
  }
  &.is-pinned-last-child::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: $space-xs;
    background: linear-gradient(
      270deg,
      #e6e6e6 0%,
      rgba(248, 247, 247, 0.966667) 14.06%,
      rgba(239, 239, 239, 0.49) 27.34%,
      rgba(255, 255, 255, 0) 117.19%
    );
  }
}

.pointer {
  cursor: pointer;
}

.is-on-top {
  z-index: z('modal') + 1;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.row-actions-cell {
  position: sticky;
  right: 0;
  width: $space-m * 8;
  white-space: nowrap;
  overflow: visible;
  &.is-group {
    padding-top: $space-m - $space-xs;
  }
  &:not(.is-group) {
    padding: $space-m $space-m * 1.5;
  }
  &.has-actions::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: $space-xs;
    background: linear-gradient(
      90deg,
      #e6e6e6 0%,
      rgba(248, 247, 247, 1) 14.06%,
      rgba(239, 239, 239, 0.1) 27.34%,
      rgba(255, 255, 255, 0) 117.19%
    );
  }
  span:not(:first-child) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.row-actions-menu {
  display: inline-flex;
  ul {
    padding: $space-m;
    li:not(.row-action-disabled):hover * {
      color: DataTable('border-color');
      fill: DataTable('border-color');
    }
  }
}

.row-action-icon {
  margin-right: $space-s;
  &:not(.row-action-disabled):hover svg {
    fill: DataTable('border-color');
  }
  &:not(.row-action-disabled):hover span {
    color: DataTable('border-color');
  }
}

.drag-chip {
  position: absolute;
  top: 75px;
  width: max-content;
  height: max-content;
  background: DataTable('header-background-light');
  padding: $space-m * 1.25 $space-m * 1.75;
  border-radius: $border-radius * 0.5;
  box-shadow: $multishadow;
  cursor: move;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.is-visible {
  opacity: 1;
  visibility: visible;
}

.is-not-visible {
  opacity: 0;
  visibility: hidden;
}

.disabled {
  cursor: not-allowed;
}

.empty-table {
  padding-top: $space-l;
  position: absolute;
  width: 100%;
}

.title {
  text-transform: capitalize;
}
