@import '../../css/variables/all.scss';
@import '../../css/functions/var-map.scss';

.wrapper {
  position: relative;
  position: relative;
  input[type='text'],
  .number-filter-input {
    padding-left: $space-m * 1.4;
  }
}

.filter-icon {
  position: absolute;
  top: $space-s;
  left: $space-xs;
}

.has-filter {
  border-color: TextInput('default-focus-border') !important;
  color: TextInput('default-focus-border') !important;
  box-shadow: 0 0 0 1px TextInput('default-focus-border') !important;
}
