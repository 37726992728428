@import '~tnx-ui/src/css/variables/all';

$section-border: $neutral--light;

.section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid $section-border;
}
