@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';

.price-revision-group {
  padding-top: $space-l;
}

.price-revision-table {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  @include for-tablet-portrait-down {
    flex-direction: column;
  }
}

.price-revision-date {
  width: $space-base-unit * 6;
  flex-grow: 0;
  flex-shrink: 0;
  padding: $space-inset-stretch-m;
  padding-left: 0;
  word-wrap: break-word;
}

.price-revision-details {
  flex-grow: 1;
  padding: $space-inset-stretch-m;
  padding-left: $space-m;
  border-left: 1px solid $border-dark-hairline;
  padding-bottom: $space-m;
}

.actions {
  margin-top: -$space-m;
  margin-bottom: -$space-m;
  margin-left: $space-m;
}
