@import '../../css/variables/all.scss';

.list {
  display: flex;
  flex-direction: column;

  &.is-bullet {
    list-style-type: disc;
  }
  &.is-numbered {
    list-style-type: decimal;
  }

  &.inline {
    flex-direction: row;
  }
}

.expandable-container {
  padding-top: $space-m;
}

.toggle-button {
  width: 100%;
  cursor: pointer;
}
