@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/mixins/breakpoints.scss';
@import '~tnx-ui/src/css/functions/z-layers';

.ninja-search {
  width: 70vw;
  max-width: $space-search-bar-width;
  @include for-tablet-landscape-up {
    &.has-side-bar {
      margin-left: $space-side-bar-width;
    }
  }
}
.ninja-search-options-container {
  width: 70vw;
  overflow: hidden;
  border-width: $border-width;
  border-color: $border-hairline;
  border-radius: $border-radius;
  box-shadow: 0 6px 16px 2px transparentize($neutral--dark-2, 0.7);
  background-color: $neutral--light-4;
  margin-left: 0px;
  z-index: z('base') + 5;
  @include for-tablet-portrait-up {
    max-width: 100vw;
  }
  @include for-tablet-landscape-up {
    max-width: $space-search-bar-width;
    margin-left: -$space-side-bar-width;
  }
  @include for-desktop-up {
    margin-left: 0px;
  }
  @include for-tablet-portrait-down {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.header {
  padding: $space-l $space-m $space-m $space-m;
  border-bottom: 1px solid $border-hairline;
}

.ninja-div {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: $space-inset-l;
  padding-bottom: 0px;
  &:focus {
    outline: 0;
  }
  @include for-tablet-portrait-up {
    max-height: 55vh;
  }
}

.header {
  padding: $space-inset-m;
}

.search-area {
  padding: $space-inset-m;
}

.button-area {
  padding: $space-inset-m;
  border-top: 1px solid $border-dark-hairline;
  text-align: center;
}
