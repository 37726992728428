@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers';

$space-dots: $space-m;

.slider-wrapper {
  height: 100%;
  width: 100%;
  min-height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &.has-dots {
    margin-top: -$space-dots;
    overflow: visible;
  }
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  border-radius: 50%;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  z-index: z('base') + 2;
  opacity: 0.6;
  transition: all 0.3s;
}

.next {
  right: 0px;
}

.prev {
  left: 0px;
}

.slide {
  position: absolute;
  max-width: 100%;
  background: $neutral--light-4;
}

.dots {
  z-index: z('base') + 2;
  bottom: -$space-dots;
  height: $space-dots * 4;
  width: 100%;
  position: absolute;
  justify-content: center;
  align-items: flex-end;
  flex-flow: wrap;
}

.dot {
  display: flex;
  margin-bottom: $space-xs;
  background-color: CountBadge('background');
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  &.is-active {
    background-color: Button('primary-regular-background');
  }
}
