@import '../../css/variables/all';
@import '../../css/functions/var-map';

.link-button {
  display: inline-block;
  color: LinkButton('text');
  margin-right: $space-xs;
  cursor: pointer;
  &:hover {
    color: LinkButton('hover');
  }
}
