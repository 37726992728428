@import '../../css/variables/all';
@import '../../css/mixins/typography-base';
@import '../../css/functions/var-map';
.icon {
  @include font-size-m;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.55em;
  height: 1.55em;
  > svg {
    fill: Icon(default);
    stroke: none;
    height: inherit;
  }
  &.is-huge {
    @include font-size-xxl;
    width: 2em;
    height: 2em;
  }
  &.is-large {
    @include font-size-l;
    width: 1.65em;
    height: 1.65em;
  }
  &.is-small {
    @include font-size-s;
    width: 1.5em;
    height: 1.5em;
  }
  &.is-color-bright {
    > svg {
      fill: Icon(bright);
    }
  }
  &.is-color-danger {
    > svg {
      fill: Icon(danger);
    }
  }
  &.is-color-dark {
    > svg {
      fill: Icon(dark);
    }
  }
  &.is-color-default {
    > svg {
      fill: Icon(default);
    }
  }
  &.is-color-light {
    > svg {
      fill: Icon(light);
    }
  }
  &.is-color-neutral {
    > svg {
      fill: Icon(neutral);
    }
  }
  &.is-color-primary {
    > svg {
      fill: Icon(primary);
    }
  }
  &.is-color-primary-blue {
    > svg {
      fill: Icon(primary-blue);
    }
  }
  &.is-color-secondary {
    > svg {
      fill: Icon(secondary);
    }
  }
  &.is-color-success {
    > svg {
      fill: Icon(success);
    }
  }
  &.is-color-warning {
    > svg {
      fill: Icon(warning);
    }
  }
  &.is-color-inherit {
    > svg {
      fill: inherit;
    }
  }
}

// Style is made global to allow for partial css selector
// Reason: Balance iconsets by giving feather icons a slight padding
.icon:global([class*='is-fe-']) {
  padding: 0.25em;
}

.inline-wrapper {
  display: flex;
  align-items: center;
  width: auto;
  // Allow icon to be taller than font without letter button height grow
  height: 0;
}
