@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/functions/var-map';

.dashboard-columns {
  display: flex;
  flex-direction: column;
  &.is-prematch {
    flex-direction: column-reverse;
  }
  .dashboard-column {
    width: 100%;
  }
  @include for-tablet-landscape-up {
    flex-direction: row;
    &.is-prematch {
      flex-direction: row;
    }
    .dashboard-column {
      width: 41.666%;
    }
  }
}

.activities-detail-container {
  position: sticky;
  top: 0;
  max-height: 90vh;
}

.activity {
  display: flex;
  flex-direction: row;
  @include for-tablet-landscape-up {
    padding: $space-m;
  }
}

.activity-content {
  flex-grow: 1;
}
.activity-title-container {
  justify-content: left;
  .activity-title {
    margin-right: $space-s;
    padding-right: $space-s;
    border-right: 1px solid $border-dark-hairline;
  }
  @include for-tablet-landscape-up {
    justify-content: space-between;
    .activity-title {
      border-right: 0px;
    }
  }
}

.activities-time-window-latest {
  display: flex;
}

.details {
  display: flex;
  flex-direction: row;
}

.details-values {
  margin-right: $space-m;
}

.activity-item {
  border-bottom: 1px solid $border-hairline;
  cursor: pointer;
  outline: 0;
  &:hover,
  &:focus {
    background: Activities('list-item-hover-background');
  }
  &.is-selected {
    background: Activities('list-item-selected-background');
    border-bottom-color: Activities('list-item-selected-border');
    &:focus {
      border-bottom-color: Activities('list-item-selected-focus-border');
    }
  }
}

.selected-activity-full-address-label {
  flex-shrink: 0;
  align-self: flex-start;
}

.selected-activity-cargo {
  align-items: flex-start;
}

.actions-container {
  display: flex;
  align-items: center;
}

.actions-buttons-container {
  position: relative;
}

.actions-buttons-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
}

.actions-button {
  position: absolute;
  white-space: nowrap;
}

.message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.actions-bid-amount {
  width: $space-xl * 3;
}

.options-wrapper {
  flex-grow: 1;
}

.options-list-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  flex-grow: 1;
  height: $space-l + $space-m;
}

.option-radio {
  align-self: flex-start;
  margin-right: $space-m;
}

.option-label {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  align-items: flex-start;
}

.option {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  height: 100%;
  border-right: 1px solid $neutral;
  &.is-disabled {
    opacity: 0.6;
    > label {
      cursor: not-allowed;
    }
  }
}

.status-table {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.status-date {
  min-width: $space-m * 9;
  padding: $space-inset-stretch-m;
  padding-left: 0;
}

.status-details {
  flex-grow: 1;
  padding: $space-inset-stretch-m;
  padding-left: $space-m;
  border-left: 1px solid $border-dark-hairline;
}

.price-revision-group {
  padding-top: $space-l;
}

.price-revision-table {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.price-revision-date {
  min-width: 96px;
  padding: $space-inset-stretch-m;
  padding-left: 0;
}

.price-revision-details {
  flex-grow: 1;
  padding: $space-inset-stretch-m;
  padding-left: $space-m;
  border-left: 1px solid $border-dark-hairline;
  padding-bottom: $space-m;
}

.price-revision-statistics {
  display: flex;
  justify-content: space-between;
}

.price-revision-statistic {
  max-width: 96px;
  margin-right: $space-s;
}

.dashboard-action-link {
  text-decoration: none;
}

.dashboard-cargo-order-owning-company {
  padding-bottom: $space-m;
  border-bottom: 1px solid $border-hairline;
}

@include for-tablet-portrait-down {
  .dash-key-value-container {
    display: block;
  }
  .dash-key-value:not(:last-child),
  .dashboard-action-list-item {
    margin-bottom: $space-l;
  }
}

.time-window-warning {
  margin-top: -$space-s;
  margin-left: $space-xs;
}

.map-container {
  height: 20rem;
}

.owning-org-logo-container {
  padding-left: $space-l;
  margin-left: $space-l;
  border-left: 1px solid $border-hairline;

  img {
    height: $space-l + $space-m;
    width: auto;
  }
}
