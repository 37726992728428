@import '~tnx-ui/src/css/variables/all';

.logo {
  max-height: $space-m * 6;
  max-width: $space-m * 10;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
