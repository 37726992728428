@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/mixins/input';

.popover-container {
  padding: $space-s $space-m;
  margin-top: -$space-s;
}

.expression-input {
  padding: $space-s;
  min-width: $space-l * 6;
  max-width: 100%;
  min-height: $space-l + $space-xs;
  border-radius: $border-radius;
  border: 1px solid $border-hairline;
  line-height: 1.6;
  &:focus {
    outline: none;
    background-color: TextInput('default-focus-background');
    border-color: TextInput('default-focus-border');
    color: TextInput('default-focus-text');
    box-shadow: 0 0 0 1px TextInput('default-focus-border');
  }
  &::placeholder {
    color: TextInput('default-regular-placeholder');
  }
  &.has-error {
    background-color: TextInput('danger-focus-background');
    border-color: TextInput('danger-focus-border');
    color: TextInput('danger-focus-text');
    box-shadow: 0 0 0 1px TextInput('danger-focus-border');
  }
  &.has-invalid-brackets {
    background-color: TextInput('warning-focus-background');
    border-color: TextInput('warning-focus-border');
    color: TextInput('warning-focus-text');
    box-shadow: 0 0 0 1px TextInput('warning-focus-border');
  }
}

.function-text {
  color: Typography('secondary');
  font-weight: 500;
}

.variable-text {
  background-color: Chip('light-solid-background-regular');
  padding: $space-xs * 0.5 $space-s;
  border-radius: $border-radius * 2;

  &.color-light-secondary {
    background-color: Chip('light-secondary-solid-background-regular');
  }
  &.color-light-positive {
    background-color: Chip('light-positive-solid-background-regular');
  }
  &.color-warning {
    background-color: Chip('warning-solid-background-regular');
  }
}
