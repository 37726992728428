@import '../../css/variables/all';
@import '../../css//functions/var-map';

.wrapper {
  position: relative;
}
.route-container {
  position: relative;
}

.route-start {
  padding-left: $space-m;
}

.route-node {
  border-color: Typography(primary);
  background-color: $neutral--light-4;
  border-width: $border-width;
  border-style: solid;
  position: absolute;
  left: 3px;
  box-sizing: content-box;
  width: $space-s;
  height: $space-s;
  border-radius: 50%;
}

.solid-node {
  background-color: Typography(primary);
}

.route-title {
  position: absolute;
  top: -2px;
  left: $space-m + $space-s;
  height: 100%;
  pointer-events: none;
}

.route-details {
  padding: $space-l * 0.75 $space-s $space-m + $space-s $space-m + $space-s;
  &.is-last {
    padding: $space-l * 0.75 $space-s 0 $space-m + $space-s;
  }
}

.route-border-dashed,
.route-border-solid {
  border-left-width: $border-width;
  border-color: $border-dark-hairline;
  position: absolute;
  top: $space-s + ($space-xs*0.5);
  bottom: -$space-s;
  left: $space-s;
}

.route-border-dashed {
  border-left-style: dashed;
  border-color: $border-dark-hairline;
}

.route-border-solid {
  border-left-style: solid;
}
