@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

@mixin dismiss-button($type: 'regular') {
  background-color: Message('dismiss-' + $type + '-background');
  border-color: Message('dismiss-' + $type + '-border');
  svg {
    fill: Message('dismiss-' + $type + '-text');
  }
  &:hover:enabled,
  &:focus:enabled {
    background-color: Message('dismiss-' + $type + '-hover-background');
    border-color: Message('dismiss-' + $type + '-hover-border');
    transition: border-color 0.15s ease-out, background-color 0.15s ease-out, color 0.15s ease-out;
    svg {
      fill: Message('dismiss-' + $type + '-hover-text');
    }
  }
  &:focus:enabled {
    background-color: Message('dismiss-' + $type + '-hover-background');
    border-color: Message('dismiss-' + $type + '-hover-background');
    outline: 0;
  }
  &:active:enabled {
    background-color: Message('dismiss-' + $type + '-background');
  }
}

.wrapper {
  border-radius: $border-radius-round;
  width: 100%;
  background-color: Message('regular-background');
  .icon {
    svg {
      fill: Message('regular-icon');
    }
  }
  .dismiss {
    @include dismiss-button();
  }

  &.is-color-danger {
    box-shadow: 0 0 0px 1px transparentize($danger, 0.3);
    background-color: Message('danger-background');
    .icon {
      svg {
        fill: Message('danger-icon');
      }
    }
    .dismiss {
      @include dismiss-button($type: 'danger');
    }
  }
  &.is-color-success {
    box-shadow: 0 0 0px 1px transparentize($success, 0.3);
    background-color: Message('success-background');
    .icon {
      svg {
        fill: Message('success-icon');
      }
    }
    .dismiss {
      @include dismiss-button($type: 'success');
    }
  }
  &.is-color-warning {
    box-shadow: 0 0 0px 1px transparentize($warning, 0.3);
    background-color: Message('warning-background');
    .icon {
      svg {
        fill: Message('warning-icon');
      }
    }
    .dismiss {
      @include dismiss-button($type: 'warning');
    }
  }
  &.is-color-light {
    box-shadow: 0 0 0px 1px transparentize($neutral--light-2, 0.3);
    background-color: Message('light-background');
    .icon {
      svg {
        fill: Message('light-icon');
      }
    }
    .dismiss {
      @include dismiss-button($type: 'light');
    }
  }
}

.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.is-size-default {
    padding: $space-inset-m;
  }
  &.is-size-small {
    padding: $space-inset-squish-m;
  }
}

.dismiss-wrapper {
  align-self: center;
}

.message-footer {
  padding: 0px $space-m $space-m;
}
