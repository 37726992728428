@import '~tnx-ui/src/css/variables/all';

.disabled {
  cursor: not-allowed;
  input {
    cursor: not-allowed;
  }
}

.overall {
  padding-bottom: $space-m;
  border-bottom: $border-width solid $border-hairline;
}
