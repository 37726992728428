@import '../../css/variables/all';
@import '../../css/functions/var-map';

.progress-bar-wrapper {
  width: $space-default * 10;
  height: $space-s;
  transition: width 0.3s ease-in;
  background: LoadMore('progress-bar-background');
  border-radius: $border-radius-round;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: LoadMore('progress');
}
