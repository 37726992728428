@import '../../css/variables/all';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

.typography {
  &.is-bright {
    color: Typography(bright);
  }
  &.is-danger {
    color: Typography(danger);
  }
  &.is-dark {
    color: Typography(dark);
  }
  &.is-default {
    color: Typography(default);
  }
  &.is-light {
    color: Typography(light);
  }
  &.is-primary {
    color: Typography(primary);
  }
  &.is-secondary {
    color: Typography(secondary);
  }
  &.is-success {
    color: Typography(success);
  }
  &.is-warning {
    color: Typography(warning);
  }
  &.is-inherit {
    color: inherit;
  }

  &.is-center {
    text-align: center;
  }
  &.is-center {
    text-align: center;
  }
  &.is-right {
    text-align: right;
  }
  &.is-inline {
    display: inline-block;
  }

  &.has-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1em;
  }

  &.is-body {
    &:not(.has-text-crop) {
      @include body(false);
    }
    &.has-text-crop {
      @include body;
    }
    &.is-bold {
      &:not(.has-text-crop) {
        @include body-bold(false);
      }
      &.has-text-crop {
        @include body-bold;
      }
    }
  }
  &.is-body-l {
    &:not(.has-text-crop) {
      @include body-l(false);
    }
    &.has-text-crop {
      @include body-l;
    }
    &.is-bold {
      &:not(.has-text-crop) {
        @include body-l-bold(false);
      }
      &.has-text-crop {
        @include body-l-bold;
      }
    }
  }
  &.is-display {
    @include display;
  }
  &.is-display-l {
    @include display-l;
  }
  &.is-display-xl-bold {
    @include display-xl-bold;
  }
  &.is-display-xl {
    @include display-xl;
  }
  &.is-display-xxl {
    @include display-xxl;
  }
  &.is-fine-print {
    &:not(.has-text-crop) {
      @include fine-print(false);
    }
    &.has-text-crop {
      @include fine-print;
    }
    &.is-bold {
      &:not(.has-text-crop) {
        @include fine-print-bold(false);
      }
      &.has-text-crop {
        @include fine-print-bold;
      }
    }
  }
  &.is-fine-print-s {
    &:not(.has-text-crop) {
      @include fine-print-s(false);
    }
    &.has-text-crop {
      @include fine-print-s;
    }
    &.is-bold {
      &:not(.has-text-crop) {
        @include fine-print-s-bold(false);
      }
      &.has-text-crop {
        @include fine-print-s-bold;
      }
    }
  }
  &.is-heading {
    @include heading;
  }
  &.is-heading-s {
    @include heading-s;
  }
  &.is-heading-l {
    @include heading-l;
  }
  &.is-heading-xl {
    @include heading-xl;
  }
  &.is-heading-xxl {
    @include heading-xxl;
  }
  &.is-sub-heading {
    @include sub-heading;
  }
  &.is-inline-text {
    display: inline-block;
    margin-right: $space-xs;
  }
}
