@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';

.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.day-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: $space-stack-s;
}

.day-header {
  flex-shrink: 0;
  width: $space-l * 1.5;
}

.slots-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
}

.invisible-radio {
  display: none;
}

.time-slot {
  display: flex;
  padding: $space-s $space-xs;
  border-radius: $border-radius-sharp;
  background: TimeSlot('background');
  border: 0;
  user-select: none;
  width: $space-xl + $space-s;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
  &:hover {
    background: TimeSlot('background-hover');
    cursor: pointer;
    > .time-slot-text {
      color: TimeSlot('text-hover');
      &.is-checked {
        color: TimeSlot('text-checked-hover');
        &.is-invalid {
          color: TimeSlot('text-checked-invalid-hover');
        }
      }
      &.is-invalid {
        color: TimeSlot('text-invalid-hover');
      }
    }
  }
  &:active {
    outline-color: TimeSlot('background-checked');
    &.is-invalid {
      outline-color: TimeSlot('background-checked-invalid');
    }
  }
  &.is-checked {
    background: TimeSlot('background-checked');
    outline: none;
    &:hover {
      background: TimeSlot('background-checked-hover');
      cursor: pointer;
    }
    &.is-invalid {
      background: TimeSlot('background-checked-invalid');
      &:hover {
        background: TimeSlot('background-checked-invalid-hover');
        cursor: pointer;
      }
    }
  }
  &.is-invalid {
    background: TimeSlot('background-invalid');
    &:hover {
      background: TimeSlot('background-invalid-hover');
      cursor: pointer;
    }
  }
  > .time-slot-text {
    color: TimeSlot('text');
    &.is-checked {
      color: TimeSlot('text-checked');
      &.is-invalid {
        color: TimeSlot('text-checked-invalid');
      }
    }
    &.is-invalid {
      color: TimeSlot('text-invalid');
    }
  }
}
