@import '../../css/variables/all';
@import '../../css/functions/var-map';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.count-badge {
  display: flex;
  margin-left: $space-xs * 0.5;
  margin-bottom: $space-xs * 0.5;
  background-color: CountBadge('background');
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  border-radius: 50%;

  &.bright {
    background-color: CountBadge('background-bright');
  }
  &.is-medium {
    height: $space-m + $space-s;
    width: $space-m + $space-s;
  }
  &.is-long {
    height: $space-l;
    width: $space-l;
  }
  &.is-extra-long {
    height: $space-l + $space-m;
    width: $space-l + $space-m;
  }
}
