@use 'sass:math';

@import '../variables/typography.scss';
@import './breakpoints.scss';

// FONT FAMILY AND TYPE
// --------------------
@mixin font-family {
  font-family: $base-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin type-primary-light {
  @include font-family;
  font-style: normal;
  font-weight: $font-weight-light;
}

@mixin type-primary-regular {
  @include font-family;
  font-style: normal;
  font-weight: $font-weight-normal;
}

@mixin type-primary-bold {
  @include font-family;
  font-style: normal;
  font-weight: $font-weight-bold;
}

// FONT SIZES
// ----------
@mixin font-size-xs {
  font-size: $type-mobile-xs;
  @include for-tablet-portrait-up {
    font-size: $type-tablet-xs;
  }
  @include for-desktop-up {
    font-size: $type-desktop-xs;
  }
  @include for-wide-desktop-up {
    font-size: $type-wide-desktop-xs;
  }
}

@mixin font-size-s {
  font-size: $type-mobile-s;
  @include for-tablet-portrait-up {
    font-size: $type-tablet-s;
  }
  @include for-desktop-up {
    font-size: $type-desktop-s;
  }
  @include for-wide-desktop-up {
    font-size: $type-wide-desktop-s;
  }
}

@mixin font-size-m {
  font-size: $type-mobile-m;
  @include for-tablet-portrait-up {
    font-size: $type-tablet-m;
  }
  @include for-desktop-up {
    font-size: $type-desktop-m;
  }
  @include for-wide-desktop-up {
    font-size: $type-wide-desktop-m;
  }
}

@mixin font-size-l {
  font-size: $type-mobile-l;
  @include for-tablet-portrait-up {
    font-size: $type-tablet-l;
  }
  @include for-desktop-up {
    font-size: $type-desktop-l;
  }
  @include for-wide-desktop-up {
    font-size: $type-wide-desktop-l;
  }
}

@mixin font-size-xl {
  font-size: $type-mobile-xl;
  @include for-tablet-portrait-up {
    font-size: $type-tablet-xl;
  }
  @include for-desktop-up {
    font-size: $type-desktop-xl;
  }
  @include for-wide-desktop-up {
    font-size: $type-wide-desktop-xl;
  }
}

@mixin font-size-xxl {
  font-size: $type-mobile-xxl;
  @include for-tablet-portrait-up {
    font-size: $type-tablet-xxl;
  }
  @include for-desktop-up {
    font-size: $type-desktop-xxl;
  }
  @include for-wide-desktop-up {
    font-size: $type-wide-desktop-xxl;
  }
}

@mixin font-size-xxxl {
  font-size: $type-mobile-xxxl;
  @include for-tablet-portrait-up {
    font-size: $type-tablet-xxxl;
  }
  @include for-desktop-up {
    font-size: $type-desktop-xxxl;
  }
  @include for-wide-desktop-up {
    font-size: $type-wide-desktop-xxxl;
  }
}

@mixin text-crop($line-height: 1.3, $top-adjustment: 0px, $bottom-adjustment: 0px) {
  // Configured in Step 1
  $top-crop: 10;
  $bottom-crop: 14;
  $crop-font-size: 41;
  $crop-line-height: 1.3;

  // Apply values to calculate em-based margins that work with any font size
  $dynamic-top-crop: math.div(
    max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0),
    $crop-font-size
  );
  $dynamic-bottom-crop: math.div(
    max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0),
    $crop-font-size
  );

  // Mixin output
  line-height: $line-height;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
  }

  &::after {
    margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
  }
}
// Mixin generated at: http://text-crop.eightshapes.com/?typeface-selection=custom-font&typeface=Lato&custom-typeface-name=Proxima%20Nova&custom-typeface-url=http%3A%2F%2Fgravity-tr.com%2Fassets%2Ffrontend%2Ffonts%2Fproximanova-regular-webfont.woff&custom-typeface-weight=400&custom-typeface-style=normal&weight-and-style=100&size=48&line-height=1.3&top-crop=13&bottom-crop=16

/* Usage Examples
  .my-level-1-heading-class {
      @include text-crop; // Will use default line height of 1.3
      font-size: 48px;
      margin: 0 0 0 16px;
  }

  .my-level-2-heading-class {
      @include text-crop; // Will use default line height of 1.3
      font-size: 32px; // Don't need to change any settings, will work with any font size automatically
      margin: 0 0 0 16px;
  }

  .my-body-copy-class {
      @include text-crop($line-height: 2); // Larger line height desired, set the line height via the mixin
      font-size: 16px;
  }

  // Sometimes depending on the font-size, the rendering, the browser, etc. you may need to tweak the output. 
  // You can adjust the top and bottom cropping when invoking the component using the $top-adjustment and $bottom-adjustment settings 
  
  .slight-adjustment-needed {
      @include text-crop($top-adjustment: -0.5px, $bottom-adjustment: 2px);
      font-size: 17px;
  }

  .dont-do-this {
      @include text-crop;
      font-size: 16px;
      line-height: 3; // DO NOT set line height outside of the mixin, the mixin needs the line height value to calculate the crop correctly
  }
*/
