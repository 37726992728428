@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/mixins/breakpoints.scss';
@import '~tnx-ui/src/css/functions/z-layers';

.ninja-search-option {
  margin: 0px $space-inset-xs $space-base-unit * 1.5 $space-inset-xs;
}

.wrapper {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: $space-inset-stretch-m;
  text-transform: capitalize;
  border-radius: $border-radius;
  transition: background 0.3s linear;
  &:hover {
    background: CargoDetails('drilldown-selected-background');
  }
  &.is-active {
    background: CargoDetails('drilldown-selected-background');
  }
  &.is-filter-active {
    background: NinjaSearch('filter-active-background');
    &.is-active,
    &:hover {
      background: NinjaSearch('filter-active-hover-background');
    }
  }
}

.count-badge,
.count-badge-hidden {
  display: flex;
  background: CountBadge('background');
  border-radius: $border-radius;
  align-items: center;
  justify-content: center;
  padding: $space-inset-squish-s;
}

.count-badge-hidden {
  visibility: hidden;
}

.has-no-count {
  opacity: 0.5;
}

.flexWrapper {
  width: 100%;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $border-radius;
  padding: $space-inset-xs;
  transition: background 0.3s linear;
  &:hover {
    background: $neutral--light-4;
  }
}

.button-area {
  padding: $space-inset-m;
  border-top: 1px solid $border-dark-hairline;
  text-align: center;
}
