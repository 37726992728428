@import '../../css/variables/all';
@import '../../css/functions/var-map';

.avatar {
  border-radius: 100%;
  background-color: Avatar('background');
  display: flex;
  justify-content: center;
  align-items: center;
  &.is-size-default {
    width: $space-l;
    height: $space-l;
  }
  &.is-size-small {
    width: $space-m * 1.5;
    height: $space-m * 1.5;
  }
  &.is-size-large {
    width: $space-l * 1.5;
    height: $space-l * 1.5;
  }
}

.avatar-image {
  border-radius: 100%;
  width: 100%;
}
