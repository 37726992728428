@import '~tnx-ui/src/css/variables/all';

.timelineitem-container {
  min-width: calc($space-xl * 5);
  &:hover {
    cursor: help;
  }

  .circle-space {
    position: relative;
    margin-top: -$space-m;
  }

  .content-column {
    &:hover {
      cursor: help;
    }
  }

  .content-column p,
  .children-column p {
    width: $space-xl * 2;
    padding: $space-xs 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.is-mobile {
    .circle-arrow {
      i {
        height: $space-xl;
      }
    }

    .content-column {
      margin-bottom: $space-m;
    }

    .content-column p {
      width: $space-xl * 3;
    }
  }

  &.is-first {
    .content-column,
    .children-column {
      margin-top: -$space-l;
    }
  }

  .children-column {
    p {
      line-height: 1;
    }
  }

  &.is-selected {
    .circle-space div {
      border-width: $border-width-bold * 2;
    }
  }
}

.has-children {
  padding-bottom: $space-m;
  margin-bottom: $space-s;
  border-bottom: $border-width-bold solid $neutral--light-2;
}

.tooltip-content {
  padding: $space-m;
}
