@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/general/base';
@import '~tnx-ui/src/css/mixins/typography';

.recent-open-wrapper {
  width: fit-content;
  min-width: $space-xl * 6;
  @include for-tablet-portrait-down {
    min-width: 100%;
    width: 100%;
  }
  cursor: pointer;
}

.recent-open {
  padding: $space-inset-m;
  border-color: $border-hairline;
  &:hover {
    background-color: $neutral--light-3;
  }
}

.recent-open-left {
  flex: 1;
  margin-right: $space-m;
}

.location-text {
  height: $space-xs * 3.5;
  white-space: nowrap;
  max-width: $space-xl * 2;
  @include for-tablet-portrait-down {
    max-width: $space-m * 4;
  }
  text-overflow: ellipsis;
  overflow: hidden;
}

.status-container {
  margin-left: $space-xs;
  margin-top: $space-xs;
}
.icon-arrow {
  margin: 0px $space-xs;
}
