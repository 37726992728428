@use "sass:math";

@import '~tnx-ui/src/css/variables/all';

$favorite-container-width: $space-l + $space-s;
$number-of-column: 5;
$favorite-shift: math.div($favorite-container-width, $number-of-column);

.favorite {
  width: $favorite-container-width;
}
.favorites-button {
  svg {
    fill: $warning--dark !important;
  }
}
