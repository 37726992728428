@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/functions/z-layers';

.mobile-filter-header {
  padding: $space-m + $space-s;
  padding-bottom: 0px;
  .tab-button {
    width: 100%;
    padding-left: $space-m;
    &:active {
      background: none;
    }
  }
  .tab-button,
  .link {
    border-radius: 0px;
  }
}

.mobile-filter-header-top {
  background-color: Box('background');
  padding: $space-m + $space-s;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: z('navbar');
  &.is-small {
    border-bottom: 1px solid $border-dark-hairline;
    padding: $space-m $space-m + $space-s;
  }
  &.is-absolute {
    position: absolute;
  }
  .small-left {
    flex-shrink: 1;
    padding-right: $space-m;
  }
}

.mobile-filter-header-bottom {
  margin-top: $space-top-bar-height + $space-m;
}

.has-border-right {
  border-right: 1px solid $border-dark-hairline;
  padding-right: $space-m + $space-s;
}

.result-container {
  min-width: 30vw;
}

.fav-checkbox {
  padding-top: $space-xs * 1.5;
}
