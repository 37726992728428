.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
