@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/functions/z-layers';

.sidebar {
  @include for-tablet-portrait-up {
    position: fixed;
    padding: 0px;
  }
}

.sidebar-heading {
  padding: 0px $space-m;
}

.settings-back-link {
  margin-left: -$space-xs;
}

.main {
  margin-left: $space-side-bar-width;

  @include for-tablet-portrait-down {
    padding-right: $space-l !important;
    padding-left: $space-l !important;
    margin-left: 0px;
  }
}

.notification-item {
  border-bottom: 1px solid $border-hairline;
  &.is-unsubscribing {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: calc(#{-$space-m} / 1.5 - 1px);
      left: calc(#{-$space-m} / 1.5 - 1px);
      width: calc(100% + #{$space-s});
      height: calc(100% + #{$space-s});
      border-radius: $border-radius;
      border: 2px solid $success--light;
      z-index: z('base');
    }
  }
}

.batch-item {
  border-bottom: 2px solid $border-dark-hairline;
}

.notification-action-wrapper {
  position: relative;
}

.notification-action-status {
  position: absolute;
  right: -$space-l;
  top: -2px;
}
