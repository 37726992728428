@import '~tnx-ui/src/css/variables/all.scss';
@import '~tnx-ui/src/css/general/base';
@import '~tnx-ui/src/css/mixins/typography';
@import '~tnx-ui/src/css/functions/var-map';

html {
  width: 100vw;
  overflow-x: hidden;
}

.header {
  background-color: $neutral--light-2;
  color: Typography(primary);
  padding: $space-l * 2 0;
  text-align: center;
}

.title {
  @include display-xxl;
  margin: $space-stack-m;
}

.spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  align-items: center;
}

.app-wrapper {
  background-color: $neutral--light-4;
}
