@import '../../css/variables/all';
@import '../../css/functions/var-map';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/z-layers';

.form-wrapper {
  width: 100%;
}

.description-wrapper {
  margin-top: $space-s;
}

.error-wrapper {
  position: relative;

  .error {
    z-index: z('base') + 1;
    position: absolute;
    top: -$space-s * 1.5;
    right: $space-xs;
    white-space: nowrap;
    background: $neutral--light-4;
    padding: $space-inset-xs;
  }

  &.has-label {
    .error {
      top: $space-s;
    }
  }
}

.row-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: $space-l;
  grid-row-gap: 0px;
  @include for-tablet-portrait-down {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-row-gap: $space-l;
    grid-column-gap: 0px;
    grid-template-rows: auto;
  }
}

.control-wrapper {
  margin-bottom: $space-m + $space-s;
}
