.content-placeholder {
  transform-origin: top center;
}

.word {
  height: 8px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 4px;
  background: #535353;
  opacity: 0.1;
}

.paragraph {
  margin-bottom: 8px;
}
