@use "sass:math";

@import '../../css/variables/all.scss';
@import '../../css/mixins/breakpoints';

.columns {
  @include for-tablet-portrait-up {
    display: flex;
    margin-right: -$space-s;
    > .column {
      padding-right: $space-s;
    }
    &.is-gap-no-spacing {
      margin-right: 0;
      > .column {
        padding-right: 0;
      }
    }
    &.is-gap-dense {
      margin-right: -$space-xs;
      > .column {
        padding-right: $space-xs;
      }
    }
    &.is-gap-relaxed {
      margin-right: -$space-m;
      > .column {
        padding-right: $space-m;
      }
    }
  }
  @include for-desktop-up {
    margin-right: -$space-m;
    > .column {
      padding-right: $space-m;
    }
    &.is-gap-dense {
      margin-right: -$space-s;
      > .column {
        padding-right: $space-s;
      }
    }
    &.is-gap-relaxed {
      margin-right: -$space-l;
      > .column {
        padding-right: $space-l;
      }
    }
  }
}

.column {
  flex: none;
  @include for-tablet-portrait-up {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    @for $i from 1 through 12 {
      &.is-#{$i} {
        flex: none;
        box-sizing: border-box;
        width: percentage(math.div($i, 12));
      }
      &.is-offset-#{$i} {
        flex: none;
        box-sizing: border-box;
        margin-left: percentage(math.div($i, 12));
      }
    }
  }
  @include for-desktop-up {
    padding-right: $space-m;
  }
}
