@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';

$padding-menu: $space-m + $space-s;

.item {
  color: MainMenu('text') !important;
  p,
  span {
    color: MainMenu('text') !important;
  }
  svg {
    fill: MainMenu('text') !important;
  }
  display: flex;
  align-self: center;
  min-width: 300px;
  align-content: center;
  padding: $padding-menu $space-xl $padding-menu $padding-menu;
  transition: background-color 0.15s ease-out, color 0.15s ease-out, fill 0.15s ease-out;
  cursor: pointer;
  background-color: MainMenu('background');
  user-select: none;
  &:not(.is-last) {
    border-bottom: 1px solid $border-hairline;
  }
  &.is-top {
    background-color: #f1f1f1;
  }
  &:hover {
    background-color: MainMenu('text');
    color: MainMenu('hover-text') !important;
    p,
    span {
      color: MainMenu('hover-text') !important;
    }
    svg {
      fill: MainMenu('hover-text') !important;
    }
  }
}

.menu-item-left {
  width: 30px;
}

.mobile-onboarding-hotspot-trigger {
  position: absolute;
  top: -$space-l - $space-s;
  right: 0;
  background-color: transparent;
  border-radius: $border-radius-circle;
  box-shadow: 0 2px 8px transparentize($neutral--dark-2, 0.7);
}
