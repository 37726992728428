@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/mixins/breakpoints';
@import '~tnx-ui/src/css/functions/var-map';

.wrapper {
  display: flex;
}

.detail {
  flex-grow: 1;
}

.section-header {
  &:first-child {
    margin-top: 0;
  }
  &.is-toned-down {
    text-decoration: line-through;
    opacity: 0.4;
  }
}

.action-link {
  display: block;
  white-space: nowrap;
  text-decoration: none;
  padding: $space-inset-stretch-m;
  width: 100%;
  &:hover {
    background-color: Actions('list-item-hover-background');
  }
  &.is-last-of-section {
    margin-bottom: $space-l;
  }
  &.is-active {
    background-color: Actions('list-item-selected-background');
  }
  border-bottom: 1px solid $border-hairline;
  padding: $space-m;
  @include for-tablet-landscape-up {
    border-bottom: none;
  }
}

.menu-back-link {
  border-bottom: 1px solid $border-dark-hairline;
  padding: $space-s $space-s $space-m * 1.25 $space-s;
  margin-bottom: $space-m;
}

.action {
  &.is-active {
    color: Actions('list-item-selected-color');
  }
}

.action-header {
  position: sticky;
  top: 0;
  padding: $space-s 0;
  background: Actions('header-background');
  @include for-tablet-portrait-down {
    margin-bottom: $space-s;
  }
}

.action-header-items {
  @include for-tablet-portrait-down {
    flex-direction: column;
    width: 100%;
  }
}

.button-wrapper {
  width: 100%;
}

.action-list {
  padding: $space-m;

  @include for-tablet-landscape-up {
    padding: 0px;
  }
}

.menu-icon {
  @include for-tablet-landscape-up {
    display: none;
  }
}

.cancellation-reduction-item {
  padding-bottom: $space-s;
  border-bottom: $border-width solid $border-hairline;
}

.dispute-list-item {
  padding-bottom: $space-m;
  border-bottom: $border-width solid $border-hairline;
}

.actuals-field-message,
.actuals-button-container {
  display: flex;
  height: $space-l + $space-xs;
  align-items: center;
}

.revisions-wrapper {
  display: flex;
}

.revisions-history-wrapper {
  flex-grow: 1;
}

.revision-history-group {
  margin: $space-stack-l;
}

.revision-forms-wrapper {
  width: 100%;
  max-width: $space-base-unit * 30;
  @include for-tablet-landscape-up {
    border-right: $border-width solid $border-hairline;
    padding-right: $space-l;
    margin-right: $space-l;
  }
}
.view-switch-container {
  border-bottom: 1px solid $border-hairline;
}
.view-switch-button {
  border-radius: 0px;
  &.is-active {
    background: $border-hairline;
  }
}

.actuals-button-container {
  margin-right: $space-s;
  @include for-tablet-portrait-down {
    margin-right: 0px;
    width: 100%;
  }
}

.input-container {
  &:not(:last-child) {
    margin-bottom: $space-m;
  }
  @include for-tablet-portrait-down {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.nothing-due-now-message-wrapper {
  max-width: $space-container-default;
  padding: $space-inset-stretch-l;
  text-align: center;
}
