@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';
@import '../../css/mixins';

.offers-view-wrapper {
  margin-left: $space-side-bar-closed;
  @include for-tablet-portrait-down {
    margin-left: 0px;
  }
  @include for-tablet-portrait-up {
    &.side-menu-open {
      transform: translateX($space-side-bar-open - $space-side-bar-closed);
      transition: transform 0.2s ease;
    }
    &:not(.side-menu-open) {
      transform: translateX(0);
      transition: transform 0.2s ease;
    }
  }
}

.search-side-bar {
  @include for-tablet-portrait-down {
    display: none;
  }
}

.tenders-nav {
  max-width: $space-container-narrow * 1.75;
}

.nav-mobile-item {
  width: calc(100vw / 5);
}

.nav-item-wrapper {
  display: flex;
  align-items: center;
  height: $space-m;
  justify-content: center;
  width: 100%;
}

.circle {
  margin-left: $space-s;
}
