@import '../../css/variables/all';

.inline-wrapper {
  display: flex;
  align-items: center;
  width: auto;
  // Allow spinner to be taller than font without letter button height grow
  height: 0;
}

.center-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
}

.spinner {
  display: inline-block;
  border-radius: 50%;
  opacity: 0.8;
  animation: rotation 0.8s ease infinite;
  border-style: solid;
  border-color: transparent;
  &.is-color-default {
    border-top-color: $neutral--dark;
    border-bottom-color: $neutral--dark;
  }
  &.is-color-bright {
    border-top-color: $neutral--light-3;
    border-bottom-color: $neutral--light-3;
  }
  &.is-size-default {
    width: $space-m * 1.5;
    height: $space-m * 1.5;
    border-width: 2px;
  }
  &.is-size-small {
    width: $space-m;
    height: $space-m;
    border-width: 1px;
  }
  &.is-size-large {
    width: $space-l * 1.5;
    height: $space-l * 1.5;
    border-width: 3px;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
