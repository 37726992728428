@import '../../css/variables/all';
@import '../../css/functions/var-map';

$box-size: $space-xl * 2;

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  border-radius: $border-radius;
  width: $box-size;
  height: $box-size;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  margin-bottom: $space-m;
}

.progress-icon {
  width: 80%;
  height: 80%;
}

.stroke-neutral {
  stroke: $neutral--dark;
}
.stroke-success {
  stroke: $success;
}
.stroke-danger {
  stroke: $danger;
}
.stroke-warning {
  stroke: $warning;
}
.stroke-hidden {
  stroke: transparent;
}
