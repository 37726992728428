@import '../../css/variables/all';
@import '../../css/mixins/typography';
@import '../../css/mixins/input';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

.input {
  @include input-type($type: 'danger');
  @include input-type($type: 'default');
  @include input-type($type: 'success');
  @include input-type($type: 'warning');
  &.is-clear {
    border: 0px;
    background: transparent;
    &:focus {
      border: 0px;
      box-shadow: none;
      outline: none;
    }
    &::placeholder {
      color: TextInput('default-regular-placeholder');
    }
  }
  @include input-base;
}

textarea {
  &.input {
    width: 100%;
    resize: vertical;
  }
}

.label {
  @include body-bold($hasTextCrop: false);
  display: block;
  padding-left: $space-s;
  margin: $space-stack-xs;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
