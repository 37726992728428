$space-base-unit: 16px;

$space-inset-default: $space-base-unit;
$space-inset-xs: $space-base-unit * 0.25;
$space-inset-s: $space-base-unit * 0.5;
$space-inset-m: $space-inset-default;
$space-inset-l: $space-base-unit * 1.5 $space-base-unit * 1.5 $space-base-unit * 1.5 $space-base-unit * 1.5;
$space-inset-xl: $space-base-unit * 2 $space-base-unit * 2 $space-base-unit * 2 $space-base-unit * 2;

$space-inset-squish-default: $space-base-unit * 0.5 $space-base-unit;
$space-inset-squish-s: $space-base-unit * 0.25 $space-base-unit * 0.5;
$space-inset-squish-m: $space-inset-squish-default;
$space-inset-squish-l: $space-base-unit $space-base-unit * 2;
$space-inset-squish-xl: $space-base-unit * 1.5 $space-base-unit * 4;

$space-inset-stretch-default: $space-base-unit * 0.75 $space-base-unit * 0.5;
$space-inset-stretch-s: $space-base-unit * 0.5 $space-base-unit * 0.5;
$space-inset-stretch-m: $space-inset-stretch-default;
$space-inset-stretch-l: $space-base-unit * 1.5 $space-base-unit * 1.25;
$space-inset-stretch-xl: $space-base-unit * 1.5 $space-base-unit * 2;

$spacing-units: (
  xxs: $space-base-unit * 0.125,
  xs: $space-base-unit * 0.25,
  s: $space-base-unit * 0.5,
  m: $space-base-unit,
  l: $space-base-unit * 2,
  xl: $space-base-unit * 4
);

$space-inline-default: 0 map-get($spacing-units, m) 0 0;
$space-inline-xxs: 0 map-get($spacing-units, xxs) 0 0;
$space-inline-xs: 0 map-get($spacing-units, xs) 0 0;
$space-inline-s: 0 map-get($spacing-units, s) 0 0;
$space-inline-m: $space-inline-default;
$space-inline-l: 0 map-get($spacing-units, l) 0 0;
$space-inline-xl: 0 map-get($spacing-units, xl) 0 0;

$space-stack-default: 0 0 map-get($spacing-units, m) 0;
$space-stack-xxs: 0 0 map-get($spacing-units, xxs) 0;
$space-stack-xs: 0 0 map-get($spacing-units, xs) 0;
$space-stack-s: 0 0 map-get($spacing-units, s) 0;
$space-stack-m: $space-stack-default;
$space-stack-l: 0 0 map-get($spacing-units, l) 0;
$space-stack-xl: 0 0 map-get($spacing-units, xl) 0;

$space-default: $space-base-unit;
$space-xs: $space-base-unit * 0.25;
$space-s: $space-base-unit * 0.5;
$space-m: $space-default;
$space-l: $space-base-unit * 2;
$space-xl: $space-base-unit * 4;

$space-container-default: $space-base-unit * 70;
$space-container-narrow: $space-base-unit * 33;
$space-container-wide: $space-base-unit * 100;

$space-side-bar-width: $space-default * 15;
$space-top-bar-height: $space-l + $space-m + $space-s;

$space-modal-width: $space-base-unit * 90;
$space-modal-close-header-height: $space-l + $space-s;

$space-search-bar-width: $space-container-narrow * 1.25;

$space-side-bar-open: $space-container-narrow * 0.5;
$space-side-bar-closed: $space-l + $space-m;


