// neutral
$neutral: #d4d4d4;
$neutral--light: #dddddd;
$neutral--light-1: #e6e6e6;
$neutral--light-1-1: #d1d3d4;
$neutral--light-2: #eceef1;
$neutral--light-3: #f1f1f1;
$neutral--light-3-1: #f6f6f6;
$neutral--light-4: #ffffff;
$neutral--dark: #9e9e9e;
$neutral--dark-1: #676767;
$neutral--dark-1-1: #4d4e53;
$neutral--dark-2: #313131;
$neutral--dark-3: #272b2f;
$neutral--dark-4: #222223;
$neutral--dark-5: #111113;
$neutral--dark-6: #000000;

// core
$core: #1f66e0;
$core--light: #3674de;
$core--light-1: #77a7e9;
$core--light-2: #d7e6ff;
$core--light-3: #ebf2fc;
$core--light-4: #f3f7fd;
$core--dark: #1a57be;
$core--dark-1: #1e517b;
$core--dark-2: #123549;

$secondary: #e2eef2;

// warning
$warning: #fcd13c;
$warning--light: #fedd6b;
$warning--light-1: #ffe794;
$warning--light-2: #ffeeb1;
$warning--light-3: #fff6d8;
$warning--dark: #f6b72e;
$warning--dark-1: #f1a022;

// danger
$danger: #f4555d;
$danger--light: #ee646b;
$danger--light-1: #fe9196;
$danger--light-2: #ffc8cb;
$danger--light-3: #fde0e1;
$danger--dark: #c14147;
$danger--dark-1: #9b292e;

// success
$success: #53c193;
$success--light: #6bd3a7;
$success--light-1: #8cdcbb;
$success--light-2: #cfefdc;
$success--light-3: #f4fdf7;
$success--dark: #41b99c;
$success--dark-1: #26a486;
$success--dark-2: #00b188;

// shared
$core-switch-light: $core--light-1;
$core-switch-dark: $core--light;
$primary-blue: #284a98;
