@mixin map-to-scope-vars($ThemeMap, $themeid) {
  // Convert to css variables in the org theme scope
  [data-theme='#{$themeid}'] {
    $key_index: 1;
    $parent_keys: map-keys($ThemeMap);
    @each $theme_component, $map in $ThemeMap {
      $parent_key: nth($parent_keys, $key_index);
      @each $name, $color in $map {
        --#{$parent_key}-#{$name}: #{$color};
      }
      $key_index: $key_index + 1;
    }
  }
}

@mixin map-to-root-vars($ThemeMap) {
  // Convert to css variables to default theme scope
  :root,
  [data-theme='default'] {
    $key_index: 1;
    $parent_keys: map-keys($ThemeMap);
    @each $theme_component, $map in $ThemeMap {
      $parent_key: nth($parent_keys, $key_index);
      @each $name, $color in $map {
        --#{$parent_key}-#{$name}: #{$color};
      }
      $key_index: $key_index + 1;
    }
  }
}
