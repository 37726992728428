@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: Box('background-dark');
  & .load-content-button {
    align-self: center;
  }
}
