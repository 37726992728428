@import '../../css/variables/all';

.list-container {
  display: flex;
  flex-wrap: wrap;
}

.autocomplete-with-chips {
  .label {
    margin-bottom: $space-s * 0.5;
  }
}

