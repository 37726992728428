@import '../../css/variables/all';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers';

.autocomplete-dropdown-container {
  position: relative;
  background: MainMenu('background');
  box-shadow: 0 2px 8px transparentize($neutral--dark, 0.4);
  border-radius: $border-radius-round;
  z-index: z('base') + 3;
  overflow: hidden;
  &[data-placement*='bottom-start'] {
    margin-top: $space-s;
  }
  &[data-placement*='top-start'] {
    margin-bottom: $space-s;
  }
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: $space-xl;
}

.suggestions-table {
  width: 100%;
  table-layout: auto;
}

.suggestion-item {
  cursor: pointer;
  border-bottom: 1px solid $border-hairline;
  &:last-child {
    border-bottom: 1px solid transparent;
  }
  &:hover,
  &.is-active {
    .suggestion-property {
      background: Select('hover-background');
      &.is-additional {
        background: $core--light-2;
      }
    }
    .main-text,
    .main-secondary-text {
      color: $neutral--light-4;
    }
  }
}

.suggestions-property-header {
  padding: $space-inset-m;
  border-bottom: 1px solid $border-dark-hairline;
  &.is-additional {
    background: $neutral--light-3;
    border-left: 1px solid $border-hairline;
  }
}

.font-weights {
  display: flex;
}

.suggestion-property {
  padding: $space-inset-m;
  &.is-additional {
    width: auto;
    background: $neutral--light-3;
    border-left: 1px solid $border-hairline;
  }
}

.suggestion-main-text {
  @include body-bold;
  display: block;
}

.suggestion-secondary-text {
  @include fine-print;
  display: block;
}
.search-input-wrapper {
  width: 100%;
}
.search-input {
  text-overflow: ellipsis;
}
