@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

.circle {
  background: Circle('background-defualt');
  box-shadow: Circle('box-shadow-default');
  border-radius: 50%;
  margin: $space-s;
  height: $space-m;
  width: $space-m;
  &.is-color-primary {
    background: Circle('background-primary');
    box-shadow: Circle('box-shadow-primary');
  }
  &.is-color-warning {
    background: Circle('background-warning');
    box-shadow: Circle('box-shadow-warning');
  }
  &.is-color-success {
    background: Circle('background-success');
    box-shadow: Circle('box-shadow-success');
  }
  &.is-color-light {
    background: Circle('background-light');
    box-shadow: Circle('box-shadow-light');
  }
  &.has-pulse {
    transform: scale(1);
    &.is-color-primary {
      animation: pulse-primary 2s infinite;
    }
    &.is-color-default {
      animation: pulse-default 2s infinite;
    }
    &.is-color-warning {
      animation: pulse-primary 2s infinite;
    }
  }
  &.is-size-small {
    margin: $space-xs;
    height: $space-s;
    width: $space-s;
  }
  &.is-size-medium {
    margin: $space-m;
    height: $space-s * 3;
    width: $space-s * 3;
  }
  &.is-size-large {
    margin: $space-m;
    height: $space-l;
    width: $space-l;
  }
  &.is-variant-outline {
    background: transparent;
    border: 1px solid Circle('background-defualt');
    &.is-color-primary {
      border: 1px solid Circle('background-primary');
    }
    &.is-color-warning {
      border: 1px solid Circle('background-warning');
    }
    &.is-color-success {
      border: 1px solid Circle('background-success');
    }
    &.is-color-light {
      border: 1px solid Circle('background-dark');
    }
  }
  &.is-variant-bordered {
    background: Circle('background-defualt-light');
    border-color: Circle('background-defualt');
    border-width: 2px;
    border-style: solid;
    &.is-color-primary {
      background: Circle('background-primary-light');
      border-color: Circle('background-primary');
    }
    &.is-color-warning {
      background: Circle('background-warning-light');
      border-color: Circle('background-warning');
    }
    &.is-color-success {
      background: Circle('background-success-light');
      border-color: Circle('background-success');
    }
    &.is-color-light {
      background: Circle('background-light');
      border-color: Circle('background-dark');
    }
  }
}

@keyframes pulse-default {
  0% {
    transform: scale(0.95);
    box-shadow: Circle('box-shadow-default-pulse-1');
  }

  70% {
    transform: scale(1);
    box-shadow: Circle('box-shadow-default-pulse-2');
  }

  100% {
    transform: scale(0.95);
    box-shadow: Circle('box-shadow-default-pulse-3');
  }
}

@keyframes pulse-primary {
  0% {
    transform: scale(0.95);
    box-shadow: Circle('box-shadow-primary-pulse-1');
  }

  70% {
    transform: scale(1);
    box-shadow: Circle('box-shadow-primary-pulse-2');
  }

  100% {
    transform: scale(0.95);
    box-shadow: Circle('box-shadow-primary-pulse-3');
  }
}

@keyframes pulse-primary {
  0% {
    transform: scale(0.95);
    box-shadow: Circle('box-shadow-warning-pulse-1');
  }

  70% {
    transform: scale(1);
    box-shadow: Circle('box-shadow-warning-pulse-2');
  }

  100% {
    transform: scale(0.95);
    box-shadow: Circle('box-shadow-warning-pulse-3');
  }
}
