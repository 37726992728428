@import '~tnx-ui/src/css/variables/all';

.price-revision-group {
  padding-top: $space-l;
}

.price-revision-table {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.price-revision-date {
  min-width: 96px;
  padding: $space-inset-stretch-m;
  padding-left: 0;
}

.price-revision-details {
  flex-grow: 1;
  padding: $space-inset-stretch-m;
  padding-left: $space-m;
  border-left: 1px solid $border-dark-hairline;
  padding-bottom: $space-m;
}

.price-revision-price-input {
  width: $space-xl * 2;
}
