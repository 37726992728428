@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/z-layers';

$section-border: $neutral--light;

.section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid $section-border;
}

.wrapper {
  padding-bottom: $space-l; // Used instead of margin, as margin results in a jump when animated
}

.expand-button {
  position: relative;
  cursor: pointer;
}

.expand-button-background {
  position: absolute;
  background: $neutral--light-2;
  top: -$space-m * 0.5;
  left: -$space-m * 0.5;
  width: calc(100% + #{$space-m});
  height: calc(100% + #{$space-m});
  border-radius: $border-radius;
  z-index: z('base');
}

.expand-button-foreground {
  position: relative;
  z-index: z('base') + 1;
  display: flex;
  align-items: center;
  user-select: none;
}
