@import '../../css/variables/all';
@import '../../css/functions/var-map';

@mixin check-box-colors($color: 'default', $colorContext: 'bright') {
  &.is-color-context-#{$colorContext} {
    &.is-color-#{$color} {
      background-color: CheckBox('background-' + $colorContext + '-' + $color + '-unchecked');
      box-shadow: 0 0 0 1px CheckBox('border-' + $colorContext + '-' + $color + '-unchecked');
      &.is-checked {
        background-color: CheckBox('background-' + $colorContext + '-' + $color + '-checked');
        box-shadow: 0 0 0 1px CheckBox('border-' + $colorContext + '-' + $color + '-checked');
        .check {
          background-color: CheckBox('check-' + $colorContext + '-' + $color);
        }
      }
    }
  }
}

.wrapper {
  display: inline-flex;
  cursor: pointer;
}

.input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.radio-wrapper {
  position: relative;
}

.radio {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s linear;
  &.is-size-default {
    width: $space-m + $space-s;
    height: $space-m + $space-s;
    border-radius: $border-radius-round;
  }
  &.is-size-small {
    width: $space-m;
    height: $space-m;
    border-radius: $border-radius-round;
  }

  @include check-box-colors($color: 'default', $colorContext: 'bright');
  @include check-box-colors($color: 'default', $colorContext: 'mid-tone');
  @include check-box-colors($color: 'default', $colorContext: 'dark');
}

.check {
  position: absolute;
  border-radius: $border-radius-circle;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}
