@import './typography-base.scss';

@mixin display-xxl {
  @include type-primary-light;
  @include font-size-xxxl;
  @include text-crop($line-height: 1, $bottom-adjustment: 0.5px);
}

@mixin display-xl {
  @include type-primary-light;
  @include font-size-xxl;
  @include text-crop($line-height: 1.2, $bottom-adjustment: -1px);
}

@mixin display-xl-bold {
  @include type-primary-bold;
  @include font-size-xxxl;
  @include text-crop($line-height: 1.2, $bottom-adjustment: -1px);
}

@mixin display-l {
  @include type-primary-bold;
  @include font-size-xl;
  @include text-crop($line-height: 1.2, $bottom-adjustment: -1px);
}

@mixin display {
  @include type-primary-bold;
  @include font-size-l;
  @include text-crop($line-height: 1.2, $bottom-adjustment: -1px);
}

@mixin heading-xxl {
  @include display-xxl;
}

@mixin heading-xl {
  @include display-xl;
}

@mixin heading-l {
  @include display-l;
}

@mixin heading {
  @include type-primary-bold;
  @include font-size-l;
  @include text-crop($line-height: 1.2);
}

@mixin heading-s {
  @include type-primary-bold;
  @include font-size-s;
  @include text-crop($line-height: 1.5, $bottom-adjustment: -0.5px);
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

@mixin sub-heading {
  @include type-primary-regular;
  @include font-size-l;
  @include text-crop($line-height: 1.3, $bottom-adjustment: -0.5px);
}

@mixin body-l($hasTextCrop: true) {
  @include type-primary-regular;
  @include font-size-l;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5, $bottom-adjustment: -0.5px);
  }
}

@mixin body-l-bold($hasTextCrop: true) {
  @include type-primary-bold;
  @include font-size-l;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5, $bottom-adjustment: -0.5px);
  }
}

@mixin body($hasTextCrop: true) {
  @include type-primary-regular;
  @include font-size-m;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5, $bottom-adjustment: -0.5px);
  }
}

@mixin body-bold($hasTextCrop: true) {
  @include type-primary-bold;
  @include font-size-m;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5, $bottom-adjustment: -0.5px);
  }
}

@mixin fine-print-s($hasTextCrop: true) {
  @include type-primary-regular;
  @include font-size-xs;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5, $bottom-adjustment: -0.5px);
  }
  letter-spacing: 0.25px;
}

@mixin fine-print-s-bold($hasTextCrop: true) {
  @include type-primary-bold;
  @include font-size-xs;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5);
  }
  letter-spacing: 0.5px;
}

@mixin fine-print($hasTextCrop: true) {
  @include type-primary-regular;
  @include font-size-s;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5, $bottom-adjustment: -0.5px);
  }
  letter-spacing: 0.25px;
}

@mixin fine-print-bold($hasTextCrop: true) {
  @include type-primary-bold;
  @include font-size-s;
  @if $hasTextCrop {
    @include text-crop($line-height: 1.5);
  }
  letter-spacing: 0.5px;
}

@mixin button-normal {
  @include type-primary-bold;
  @include font-size-m;
  @include text-crop;
  letter-spacing: 0.075rem;
}

@mixin button-small {
  @include type-primary-bold;
  @include font-size-s;
  @include text-crop($top-adjustment: 0.5px);
  letter-spacing: 0.05rem;
}

@mixin button-large {
  @include type-primary-bold;
  @include font-size-l;
  @include text-crop;
  letter-spacing: 0.1rem;
}

@mixin input {
  @include type-primary-regular;
  @include font-size-m;
  @include text-crop;
}
@mixin input-small {
  @include type-primary-regular;
  @include font-size-s;
  @include text-crop;
}
