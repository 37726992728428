@import '../../css/variables/all';
@import '../../css/mixins/breakpoints';
@import '../../css/functions/var-map';
@import '../../css/functions/z-layers.scss';

.wrapper {
  width: 100%;
  display: flex;
  z-index: z('navbar');
  min-height: $space-top-bar-height;
  padding: $space-inset-squish-m;
  &.is-color-default {
    background-color: TopBar('background-light');
    border-bottom: 1px solid TopBar('border-bottom-light');
    p {
      color: TopBar('color-light');
    }
  }
  &.is-color-dark {
    background-color: TopBar('background-dark');
    border-bottom: 1px solid TopBar('border-bottom-dark');
    p {
      color: TopBar('color-dark');
    }
  }
  &.is-color-transparent {
    background-color: transparent;
    border-bottom: 1px solid transparent;
  }
  &.is-position-absolute {
    position: absolute;
  }
  &.is-position-sticky {
    position: sticky;
  }
  &.is-position-fixed {
    position: fixed;
  }
  &.is-alignment-bottom {
    bottom: 0px;
    left: 0px;
    @include for-tablet-portrait-down {
      padding: $space-xs * 1.5 $space-s $space-xs $space-s;
    }
  }
  &.is-alignment-top {
    top: 0px;
    left: 0px;
  }
}

.nav-left {
  flex: 1 1 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p,
  span {
    color: TopBar('color-light') !important;
  }
  svg {
    fill: TopBar('color-light') !important;
  }
}

.nav-center {
  flex: 1 1 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-right {
  flex: 1 1 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p,
  span {
    color: TopBar('color-light') !important;
  }
  svg {
    fill: TopBar('color-light') !important;
  }
}
