@import '../../css/variables/all';
@import '../../css/functions/var-map';

.icon-background {
  padding: $space-xs $space-s;
  border-radius: $border-radius * 2;
  width: fit-content;
}

.success {
  background-color: CountBadge('background-success');
}
.fail {
  background-color: CountBadge('background-danger');
}
