@import '../../css/variables/all';
@import '../../css/mixins/typography';
@import '../../css/functions/var-map';

.wrapper {
  @include input-small;
  align-items: center;
  display: inline-flex;
  outline: none;
  border-color: TextInput('default-regular-border');
  border-style: solid;
  border-width: $border-width;
  border-radius: $border-radius;
  color: TextInput('default-regular-text');
  padding: $space-s $space-xs;
  height: $space-l + $space-xs;
  white-space: nowrap;
  &:not(.is-disabled) {
    &:focus {
      border-color: TextInput('item-focus-border');
      box-shadow: 0 0 0 0.2rem TextInput('item-focus-border');
    }
  }

  > input {
    @include input-small;
    border: 0;
    padding: 0;
    text-align: center;
    outline: 0;
    &::placeholder {
      color: TextInput('default-regular-placeholder');
    }
    &:focus {
      background: TextInput('item-focus-background');
      color: TextInput('item-focus-text');
      border-radius: $border-radius;
    }
  }
  > select {
    @include input-small;
    border: 0;
    padding: 0;
    text-align: center;
    outline: 0;
    &:focus {
      box-shadow: 0 0 0 0.1rem TextInput('item-focus-border');
    }
  }
  &.has-errors {
    &:focus {
      border-color: TextInput('danger-focus-border');
      box-shadow: 0 0 0 0.2rem TextInput('danger-focus-border');
    }
    border-color: TextInput('danger-regular-border');
    box-shadow: 0 0 0 0.2rem TextInput('danger-regular-border');
  }

  > input[name='month'],
  > input[name='day'],
  > input[name='hour'],
  > input[name='minute'] {
    max-width: $space-m * 1.5;
  }

  > input[name='year'] {
    max-width: $space-m * 2.5;
  }

  &.is-editing {
    border-color: TextInput('item-focus-border');
    box-shadow: 0 0 0 0.2rem TextInput('item-focus-border');
  }
  &.is-disabled {
    background-color: TextInput('disabled-regular-background');
    cursor: not-allowed;
    input,
    select {
      background: transparent;
      cursor: not-allowed;
    }
  }
}

.separator {
  padding: 0 4px;
}

:export {
  dangerFocusBorder: TextInput('danger-focus-border');
  dangerRegularBorder: TextInput('danger-regular-border');
  defaultFocusBorder: TextInput('item-focus-border');
  defaultRegularBorder: TextInput('default-regular-border');
  focusBackground: TextInput('item-focus-background');
  fontSize: $type-mobile-s;
}
