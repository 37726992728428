@import '~tnx-ui/src/css/variables/all';
@import '~tnx-ui/src/css/functions/var-map';
@import '~tnx-ui/src/css/functions/z-layers';

.input {
  position: absolute;
  opacity: 0;
  padding: 0;
}

.label {
  display: inline-flex;
  position: relative;
  padding: $space-inset-stretch-s;
  margin: 0;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
}

.hover-background {
  position: absolute;
  border: 2px solid InlineValueSelect('hover-border');
  top: -$space-s * 0.5;
  left: -$space-s * 0.5;
  width: calc(100% + #{$space-s});
  height: calc(100% + #{$space-s});
  border-radius: $border-radius;
  z-index: z('base');
}

.active-background {
  position: absolute;
  border: 1px solid InlineValueSelect('active-border');
  top: -$space-s * 0.5;
  left: -$space-s * 0.5;
  width: calc(100% + #{$space-s});
  height: calc(100% + #{$space-s});
  border-radius: $border-radius;
  z-index: z('base');
}

.checked-background {
  position: absolute;
  background: InlineValueSelect('checked-background');
  top: -$space-s * 0.5;
  left: -$space-s * 0.5;
  width: calc(100% + #{$space-s});
  height: calc(100% + #{$space-s});
  border-radius: $border-radius;
  z-index: z('base');
}

.is-dark {
  .checked-background {
    background: $neutral--dark-2;
    border: 1px solid $neutral--dark-1;
  }
  .active-background {
    border: 1px solid $neutral--dark-1;
  }
}

.foreground {
  z-index: z('base') + 1;
}
